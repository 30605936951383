* { box-sizing: border-box; }
.resNavShade { display: flex;  width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden; position: fixed; z-index: 999999999; left: 0; right: 0; top: 0; bottom: 0; background-color: rgba(0,0,0,0.54); }
.resNav { display: flex; flex-direction: column; width: calc(100% - 64px); height: 100vh; position: fixed; z-index: 9999999999; left: 0; right: 0; top: 0; bottom: 0; background-color: var(--whiteBg); 
    -ms-transform: translateX(-105%); -moz-transform: translateX(-105%); -webkit-transform: translateX(-105%); transform: translateX(-105%); 
    -ms-transition: all 0.15s ease-in; -moz-webkit-transition: all 0.15s ease-in; transition: all 0.15s ease-in;
}
.resNavShade.fadeInShade { width: 100%; height: 100%; visibility: visible; opacity: 1; transform: cubic-bezier(0, .52, 0, 1)}
.resNav.resNavSlideIn { -ms-transform: translateX(0); -moz-transform: translateX(0); -webkit-transform: translateX(0); transform: translateX(0); -ms-transition: all 0.3s ease-in; -moz-webkit-transition: all 0.3s ease-in; transition: all 0.3s ease-in;}
.resNavHdr { display: flex; width: 100%; height: 80px; align-items: center; border-bottom: 1px solid rgba(196, 134, 134, 0.12); padding: 0 8px; }

.resNavBdy { display: flex; flex-direction: column; width: 100%; min-height: 0; }
.overflowBlockCntnr { display: block; flex: 1; overflow-y: auto; overflow-x: hidden; }
.overflowCntnt { display: flex; flex: 1; flex-direction: column; width: 100%; padding: 24px 0; }

.resNavLogoBlock { display: flex; flex: none; flex-grow: 1; align-items: center; padding: 0 8px; }
.resNavLogoBlock .resNavLogo { height: 32px; min-width: 40px; }
.resNavCloseBlock { display: flex; flex: none; min-width: 40px; position: relative; height: 40px; }
.resNavCloseBtn { display: flex; width: 40px; height: 40px; align-items: center; justify-content: center; cursor: pointer; }

.resNavListGroup { display: flex; flex-direction: column; width: 100%; padding: 8px 0; }
.resNavListItem { display: flex; flex: none; width: 100%; height: 56px; align-items: center; padding: 0 40px; }

.resNavListItem .anchorTxt { text-decoration: none; padding: 15px 8px; color: var(--blackTxtPrimaryInactive); cursor: pointer; font-weight: 500; font-size: 1.0rem; width: 100%; display: flex; flex: 1; align-items: center; }
.resNavListItem .anchorTxt:hover { color: var(--primaryDark);}
.resNavListItem .anchorActive { color: var(--primaryColor); }
.navListIcon { display: flex; flex: none; width: 20px; height: 20px; margin-right: 16px;}



@media (min-width: 768px) and (max-width: 1112px) {
    .resNav { width: 56% }
}

@media (min-width: 1113px) and (max-width: 1366px) {
    .resNavShade, .resNav { display: none; }
}

@media (min-width: 1367px) {
    .resNavShade, .resNav { display: none; }
}