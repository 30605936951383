.sideNav {display: flex; box-sizing: border-box; border-right: 1px solid rgba(0,0,0,0.12); background-color: #ffffff;     overflow-y: auto;
    overflow-x: hidden; position: fixed; top: 64px; height: 100%;}
.sideNav > ul {display: flex; margin: 0; padding: 0; flex-direction: column; flex: 1; list-style-type: none; padding: 8px 0;}
.sideNav > ul > li {display: flex; margin: 0; padding: 0 8px; list-style-type: none;}
.sideNav > ul > li > a {display: flex; flex: 1; color: var(--blackTextColor); text-decoration: none; padding: 0 8px; border-radius: 4px; height: 48px; align-items: center; font-size: 14px; font-weight: 450;}
.sideNav > ul > li > a:hover { background-color: var(--primarySuperLight); color: var(--primaryDark); }
.sideNav > ul > li > a.activeTabNav { background-color: var(--primarySuperLight); color: var(--primaryDark); }
.sideNav > ul > li > a > .imgLink {display: flex; width: 24px; align-items: center; justify-content: center;}
.sideNav > ul > li > a > .imgLink > img {width: 24px; height: 24px; -webkit-filter: grayscale(100%); filter: grayscale(100%);}
.sideNav > ul > li > a:hover > .imgLink > img {width: 24px; height: 24px; -webkit-filter: grayscale(0%); filter: grayscale(0%);}
.sideNav > ul > li > a.activeTabNav > .imgLink > img {width: 24px; height: 24px; -webkit-filter: grayscale(0%); filter: grayscale(0%);}
.sideNav > ul > li > a > .txtLink {margin-left: 16px;}


.responsiveSideNav {display: flex; box-sizing: border-box; border-right: 1px solid rgba(0,0,0,0.12); background-color: #ffffff; overflow-y: auto;
    overflow-x: hidden; position: fixed; top: 64px; height: 100%; flex-direction: column;}
.resULItem {display: flex; margin: 0; padding: 0; flex-direction: column; flex: 1; list-style-type: none; padding: 8px; }
.resULItem > li {display: flex; margin: 0; padding: 0 16px; list-style-type: none;}
.resULItem > li > a {display: flex; flex: 1; color: var(--blackTextColor); text-decoration: none; padding: 0 8px; border-radius: 4px; height: 56px; align-items: center; font-size: 14px; font-weight: 450;}
.resULItem > li > a:hover { background-color: var(--primarySuperLight); color: var(--primaryDark); }
.resULItem > li > a.activeTabNav { background-color: var(--primarySuperLight); color: var(--primaryDark); }
.resULItem > li > a > .imgLink {display: flex; min-width: 24px; align-items: center; justify-content: center;}
.resULItem > li > a > .imgLink > img {width: 20px; height: 20px; -webkit-filter: grayscale(100%); filter: grayscale(100%);}
.resULItem > li > a:hover > .imgLink > img { -webkit-filter: grayscale(0%); filter: grayscale(0%);}
.resULItem > li > a.activeTabNav > .imgLink > img {width: 20; height: 20px; -webkit-filter: grayscale(0%); filter: grayscale(0%);}
.resULItem > li > a > .txtLink { margin-left: 16px; }
.slideInSideNav {display: none;}
.resSidenavHdr { display: none; }
.resSidenavBdy { display: flex; min-height: 0; flex: 1; width: 100%; overflow-x: hidden; overflow-y: auto; scroll-behavior: smooth; }

.sideNavBranding { height: 40px; display: inline-flex; width: 100%; }
.sideNavBranding img { height: 100%; width: auto; }
.resNavCloseGroup { display: flex; flex: none; min-width: 40px; position: relative; height: 40px; }
.resNavCloseIcon { display: flex; width: 40px; height: 40px; align-items: center; justify-content: center; cursor: pointer; }

/************** TABLET RESPONSIVE *************/

@media (min-width: 768px) and (max-width: 1024px) {
    .responsiveSideNav {display: flex; flex-direction: column; top:0; left:0; z-index: 1;
        -ms-transition: all 0.3s ease; -moz-transition: all 0.3s ease; -webkit-transition: all 0.3s ease; transition: all 0.3s ease; -ms-box-shadow: -1px 1px 8px -2px rgba(0,0,0,0.24);; -moz-box-shadow: -1px 1px 8px -2px rgba(0,0,0,0.24);; -webkit-box-shadow: -1px 1px 8px -2px rgba(0,0,0,0.24);; box-shadow: -1px 1px 8px -2px rgba(0,0,0,0.24); -ms-transform: translateX(-105%); -moz-transform: translateX(-105%); -webkit-transform: translateX(-105%); transform: translateX(-105%); }
    .slideInSideNav { 
        width: calc(100% - 160px);
        box-shadow: 160px 0 0 rgba(0,0,0,0.5);
        z-index: 1024;
         -ms-transform: translateX(0px); -moz-transform: translateX(0px); -webkit-transform: translateX(0px); transform: translateX(0px); }
    .resSidenavHdr { padding: 24px 16px; display: flex; flex: none; width: 100%; border-bottom: 1px solid var(--brdrColor);  }
}
/************** MOBILE RESPONSIVE *************/

@media (min-width: 320px) and (max-width: 767px) {
.responsiveSideNav {display: flex; flex-direction: column; top:0px; left:0; z-index: 1;
    -ms-transition: all 0.3s ease; -moz-transition: all 0.3s ease; -webkit-transition: all 0.3s ease; transition: all 0.3s ease; -ms-box-shadow: -1px 1px 8px -2px rgba(0,0,0,0.24);; -moz-box-shadow: -1px 1px 8px -2px rgba(0,0,0,0.24);; -webkit-box-shadow: -1px 1px 8px -2px rgba(0,0,0,0.24);; box-shadow: -1px 1px 8px -2px rgba(0,0,0,0.24); -ms-transform: translateX(-105%); -moz-transform: translateX(-105%); -webkit-transform: translateX(-105%); transform: translateX(-105%); }
.slideInSideNav { 
    width: calc(100% - 56px);
    box-shadow: 56px 0 0 rgba(0,0,0,0.5);
    z-index: 1024;
     -ms-transform: translateX(0px); -moz-transform: translateX(0px); -webkit-transform: translateX(0px); transform: translateX(0px); }

    .resSidenavHdr { padding: 24px 16px; display: flex; flex: none; width: 100%; border-bottom: 1px solid var(--brdrColor); }

}