* {  box-sizing: border-box; }
:root {
	--whiteBg: #fff;
}

.header {z-index: 999; display: flex; flex: 1; position: fixed; height: 56px; left: 0; top: 0; right: 0;  background-color: var(--whiteBg); border-bottom: 1px solid rgba(0,0,0,0.12); align-items: center; padding: 0 24px; justify-content: space-between; box-sizing: border-box; }
.headerBgChange{background-color: #042531;}
.leftLogoBlock { display: flex; flex-grow: 1; padding: 0; align-items: center; min-width: 40px; }
.logoBlock { width: auto; height: 24px; flex: none; display:flex;}
.logoBlock img {width: auto; height: 100%; }
.logoBlock a {display: flex; align-items: center;}
.displayOrNot {display: flex !important; }
.brodcastFlash { display: flex; flex: 0 0 auto; align-items: center; }

.headerAvatarBlock { flex: 0 0 auto; display: flex; align-items: center; position: relative; z-index: 999;}
.headerAvatarBlock:hover{cursor: pointer;}
.userName {flex: 1 1 auto; padding: 0 12px 0 0; display: flex; }
.userName span {width: inherit; font-size: 14px; color: rgba(0,0,0,0.87); font-weight: 600; text-transform: capitalize;;}
/* .userName span:after { content: "\25bc"; margin-left: 4px; font-size: 10px; position: relative; top: -1px; } */
.userName span:after { content: "\25bc";
    margin-left: 4px;
    font-size: 10px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%); }
.userAvatar {flex: 0 0 auto; width: 40px; height: 40px; border-radius: 50%; background-color: var(--primaryColor); border: 1px solid var(--primaryColor); }
.userAvatar img {width: 100%; height: 100%; border-radius: 50%; object-fit: cover;}
.userAvatar:hover { border: 1px solid var(--primaryDark); }


/***** HEADER DROP DOWN MENU *****/
.headerAvaDrpDwn { display: none; position: absolute; background-color: #fff; /*min-width: 140px;*/ min-width: 180px; min-height: 40px; box-shadow: 1px -4px 48px 6px rgba(0,0,0,0.24); z-index: 999; top: 44px; border-radius: 4px; transition: 0.5s; margin-top: 12px; right: 16px; }
.headerAvaDrpDwn:after { content: ""; position: absolute; z-index: -1; width: 0; height: 0; background-color: var(--whitePrimary); top: -19px; right: 0; background-color: transparent; transform: rotate(0); border-top: 24px solid transparent; border-right: 24px solid #fff; border-bottom: 24px solid #fff; border-left: 24px solid transparent; box-shadow: -1px 8px 22px rgba(0,0,0,0); }
.headerAvaDrpDwn ul {list-style-type: none;margin: 0; padding: 8px 0; display: flex; flex-direction: column;flex: 1;}
.headerAvaDrpDwn ul li {text-decoration: none; display: flex; flex: 1;}
.headerAvaDrpDwn ul li a { color: rgba(0,0,0,0.87); padding: 12px 16px; text-decoration: none; display: flex; font-size: 14px; flex: 1; align-items: center; }
.headerAvaDrpDwn ul li a i { margin-right: 8px; }
.headerAvaDrpDwn ul li:hover a,
.headerAvaDrpDwn ul li:active a{background-color: #f5f5f5; color: #3397ED;}
.show {display: flex;}
.userName span { width: auto; max-width: 160px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; position: relative; padding-right: 12px; }
/* june 8 wed, 2021 - made this css mobie first code */
.newWebNav { display: none; }
.newHumburger { display: flex; flex: none; width: 40px; height: 40px; cursor: pointer; align-items: flex-start; justify-content: center; flex-direction: column; }
.newHumburger .burgerLine { display: flex; width: 20px; height: 2px; background-color: var(--primaryColor); border-radius: 4px; }
.newHumburger .burgerLine + * { margin-top: 4px; }
.newHumburger .burgerLine:nth-child(2) { width: 16px; }
.anchorTxtClr{text-decoration: none; padding: 12px 8px; color: #fff; cursor: pointer; font-weight: 500; font-size: 1.0rem;}
@media (min-width: 768px) and (max-width: 1112px) {
	.newWebNav { display: none; }
}

@media (min-width: 1113px) and (max-width: 1366px) {
	.header { height: 64px; }
	.logoBlock { height: 36px; }
	.displayOrNot {display: none !important;}
	.newWebNav { display: flex; flex: 1; min-width: 0; align-items: center; padding: 0 16px; justify-content: center; position: absolute; left: 50%; -moz-transform: translateX(-50%); -webkit-transform: translateX(-50%); transform: translateX(-50%) }
	.newWebNav .anchorTxt { text-decoration: none; padding: 12px 8px; color: var(--blackTxtPrimaryInactive); cursor: pointer; font-weight: 500; font-size: 1.0rem; }
	.newWebNav .anchorTxt:hover { color: var(--primaryDark);}
	.newWebNav .anchorActive { color: var(--primaryColor); }
	.newHumburger {display: none;}
	.leftLogoBlock { flex: none; }
}

@media (min-width: 1367px) {
	.header { height: 64px; }
	.logoBlock { height: 36px; }
	.displayOrNot {display: none !important;}
	.newWebNav { display: flex; flex: 1; min-width: 0; align-items: center; padding: 0 16px; justify-content: center; position: absolute; left: 50%; -moz-transform: translateX(-50%); -webkit-transform: translateX(-50%); transform: translateX(-50%);}
	.newWebNav .anchorTxt { text-decoration: none; padding: 12px 8px; color: var(--blackTxtPrimaryInactive); cursor: pointer; font-weight: 500; font-size: 1.0rem; }
	.newWebNav .anchorTxt:hover { color: var(--primaryDark);}
	.newWebNav .anchorActive { color: var(--primaryColor); }
	.newHumburger {display: none;}
	.leftLogoBlock { flex: none; }
}