.bubblesAbslutCntnr         { position: absolute; z-index: 1; top: 100px; left: 0;}
.bubbles                    { position: relative; top: 0; left: 0; width: 100%; height: 100%; z-index: 1; color: var(--primaryColor); }
.bubblesItem                { position: absolute;  list-style: none; display: flex; flex: none; align-items: center; justify-content: center; opacity: 0.2; font-size: 0.75rem; -webkit-animation: square linear 12s infinite; animation: square linear 12s infinite; }
.bubblesItem:nth-child(1)   { width: 40px; height: 40px; left: 36.333333333333334%; font-size: 0.75rem; opacity: 0.1; -webkit-animation-delay: 10s; animation-delay: 10s; }
.bubblesItem:nth-child(5)   { width: 40px; height: 40px; left: 58.66666666666667%; font-size: 0.75rem; opacity: 0.1; -webkit-animation-delay: 0s; animation-delay: 0s; }
.bubblesItem:nth-child(9)   { width: 40px; height: 40px; left: 68%; font-size: 0.75rem; opacity: 0.1; -webkit-animation-delay: 1s; animation-delay: 1s; }
.bubblesItem:nth-child(2)   { width: 12px; height: 12px; background-color: black; left: 20.666666666666668%; font-size: 0.75rem; opacity: 0.05; -webkit-animation-delay: 2s; animation-delay: 2s; }
.bubblesItem:nth-child(6)   { width: 12px; height: 12px; left: 50%; font-size: 0.75rem; opacity: 0.05; -webkit-animation-delay: 2s; animation-delay: 2s; }
.bubblesItem:nth-child(10)  { width: 16px; height: 16px; left: 83.33333333333334%; font-size: 0.75rem; opacity: 0.05; -webkit-animation-delay: 2s; animation-delay: 2s; }
.bubblesItem:nth-child(3)   { width: 40px; height: 40px; left: 78%; font-size: 0.75rem; opacity: 0.08; -webkit-animation-delay: 8s; animation-delay: 8s; }
.bubblesItem:nth-child(7)   { width: 40px; height: 40px; left: 48.33333333333334%; font-size: 0.75rem; opacity: 0.08; -webkit-animation-delay: 6s; animation-delay: 6s; }
.bubblesItem:nth-child(11)  { width: 40px; height: 40px; left: 60.66666666666669%; font-size: 0.75rem; opacity: 0.08; -webkit-animation-delay: 3s; animation-delay: 3s; }
.bubblesItem:nth-child(4)   { width: 10px; height: 10px; left: 33.333333333333336%; font-size: 0.75rem; opacity: 0.05; -webkit-animation-delay: 3s; animation-delay: 3s; }
.bubblesItem:nth-child(8)   { width: 40px; height: 40px; left: 40.66666666666667%; font-size: 0.75rem; opacity: 0.05; -webkit-animation-delay: 8s; animation-delay: 8s; }
.bubblesItem:nth-child(12)  { width: 40px; height: 40px; background-color: green; left: 60%; font-size: 0.75rem; opacity: 0.05; -webkit-animation-delay: 3s; animation-delay: 3s; }
.bubblesItem img { width: 20px; height: 20px; }

@-webkit-keyframes square {
    0%    { transform: rotate(0deg) scale(1); top: 0; }
    50%   { transform: rotate(0) scale(1.2); }
    100%  { transform: rotate(0) scale(1); top: -400px; }
  }
  @keyframes square {
    0%    { transform: rotate(0) scale(1); top: 0; }
    50%   { transform: rotate(0) scale(1.2); }
    100%  { transform: rotate(0) scale(1); top: -400px; }
  }