
.app { display: flex; position: relative; height: inherit; }

.confTimer {display: flex;
  position: absolute;
  padding: 4px 12px;
  background-color: rgb(239 73 51);
  left: 50%;
  z-index: 999;
  border-radius: 0 0 4px 4px;
  flex: none;
  align-items: center;
  top: 0;
  transform: translateX(-50%);
  color: #FFF;
  font-size: 0.87rem;
  font-weight: 600;
  cursor: pointer;}

  .clock {
    position: relative;
    transform: scale(2);
    border-radius: 50%;
    border: 1px solid;
    width: 10px;
    height: 10px;
    position: relative;
    display: flex;
    flex: none; margin-right: 8px;
  }
  
  .clock:after {
    position: absolute;
    width: 0px;
    height: 4px;
    display: block;
    border-left: 1px solid #fff;
    content: '';
    left: 4px;
    top: 0px;
    animation-duration: 1s;
  }
  
  .clock:before {
    position: absolute;
    width: 0px;
    height: 3px;
    display: block;
    border-left: 1px solid #fff;
    content: '';
    left: 4px;
    top: 1px;
    animation-duration: 600s;
  }
  
  .clock:before,.clock:after {
    transform-origin: bottom;
    animation-name: dial;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  
  @keyframes dial {
      0%   {transform: rotate(0deg);}
      100% {transform: rotate(-360deg);}
  }

.contentBodyBlock { position: relative; display: flex; flex: 1 1 auto; align-items: center; justify-content: center; box-sizing: border-box; flex-direction: column;}
.contentFotrBlock { position: relative; display: flex; flex: 0 0 auto; align-items: center; justify-content: center; height: 56px; box-sizing: border-box; flex-direction: column;}
.contentInnerBlock{display:flex; flex-direction: column; flex:1;}

/* .wrapperFullH { position: relative; padding: 0; margin: 0; width: 100%; min-height: 100%; } */
.wrapperFullH { position: relative; padding: 0; margin: 0; width: 100%; height: 100%; }
.wrapperBody { display: flex; min-height: 100%; width: 100%; position: relative; }
.quickStartBtn { cursor: pointer; padding: 0; position: absolute; display: flex; align-items: center; justify-content: center; width: 64px; height: 64px; background-color: var(--whiteBg); border-radius: 50%; right: 24px; bottom: 24px; box-shadow: 0 3px 12px rgba(0,0,0,0.05); }
.quickStartBtn:hover { box-shadow: 0 3px 12px rgba(0,0,0,0.16); }
.quickStartBtn img {width: 32px; height: 32px; }

.rowBlock { display: flex; flex: 1; position: relative; overflow-x: hidden; overflow-y: auto;}
.col2 { display: flex; width: 50%; box-sizing: border-box; flex: 1 1 auto;}
.brdrLeft { border-left: 1px solid rgba(0,0,0,0.12); }
.block24 { display: flex; flex: 1; flex-direction: column; padding: 24px 80px; }
.pLightTxt {font-size: 14px; color:rgba(0,0,0,0.54);}
/***** Affiliate Terms *********/
.contentTerms {display: flex; flex-direction:column; margin: 0 208px;}
.contentTerms h2{font-size: 64px; font-weight: 200; margin:8px 0; text-align: center;}
.termsParaBlock {display: flex; flex-direction: column; margin-bottom: 16px;}
.termsParaBlock:last-child { margin-bottom: 0;}
.termsParaBlock h3{font-size: 28px; font-weight: 300; color:rgba(0,0,0,0.87);}
.termsParaBlock p {font-size: 14px; font-weight: 400; color:rgba(0,0,0,0.54);}
.contentTerms button {align-items: center; margin:8px auto; border-radius: 50px; min-width: 120px;}


.bodyBg {background-color:#f7f8fa !important;}
.wirelessShareIcon { padding:24px 0; border-bottom:1px solid rgba(0,0,0,0.12); position: relative; display: flex; align-items: center; justify-content: center; width: 960px; height: auto;}
.wirelessShareIcon img{width: 100%; height: auto;}
.wirelessShareTxt {display: flex; flex-direction: column; align-items: center; justify-content: center;}
.wirelessShareTxt h4 {font-size: 20px; margin:24px 0px 8px; font-weight: 400; }
.wirelessShareTxt button {min-width: 160px !important; margin-top:8px; font-weight: 550px;}
.wirelessShareTxt button span {font-weight: 550;}
.customOutlineFab {margin: 8px 16px 8px 0 !important;; flex: 1; min-width: 420px !important; }
.customOutlineFab>div {background-color: #fff !important; border-radius: 50px; padding:0 !important;}
.customOutlineFab>div fieldset {padding:14px !important; border-radius:50px !important;}
.customOutlineFab label {line-height: 0.5!important;}

.fullWidth {width: 100% !important;}
.width50 {width: 50%;}
.redStar { color: #ff0000; }
.nomrgBtm {margin-bottom: 0 !important;}
.nomrg {margin:0 !important;}
.nopdng {padding:0 !important;}
.noShadow {box-shadow:unset !important;}
.mrgRight {margin-right:16px !important;}
.mrgLeft {margin-left:16px !important;}
.mrgTop8 {margin-top: 8px!important;}
.mrgLeft8 {margin-left:8px !important;}
.mrgRight8 {margin-right:8px !important;}
.mrgRight32{margin-right: 32px !important;}
.mrgLeft32 {margin-left:32px !important;}
.mrgTop32{margin-top: 32px !important;}
.mrgBtm32 {margin-bottom:32px !important;}
.greyIcon {fill:rgba(0,0,0,0.38)!important;}
.margBtm {margin-bottom:16px !important;}
.horzPdng {padding:0 16px !important;}
.vertPdng {padding:16px 0 !important;}
.vertMrg {margin:16px 0 !important;}
.labelMrg {margin:4px 0 0 0 !important;}
.cornerBtns {display: flex; align-items: center; justify-content: flex-end; flex: 1;}
.cornerIcon8 {position: absolute; right:8px; top:8px;}
.playerCloseIcon {position: absolute; right:8px; top:64px;}
.playerclose {background-color: rgba(0,0,0,0.2); z-index:1; border-radius: 50%; width:unset !important;}
.whiteIconBtn button {color:#fff;}
.whiteIconBtn button:hover{background-color: rgba(255, 255, 255, 0.18);}
.column50 {
width: calc(50% - 8px);
display: -ms-inline-flexbox;
display: inline-flex;
-webkit-box-sizing: border-box;
box-sizing: border-box;}

/* .contentPdngBlock {display: block; padding: 0 24px 24px; flex:1; box-sizing: border-box;} */
.contentPdngBlock {display: block; padding: 0; box-sizing: border-box; width: calc(100% - 32px);}
.primaryLink {color:#336ebf; margin-left: 8px;}
.primaryLink:hover{cursor: pointer; text-decoration: underline;}

/* Hiding Table footer p tag */
.MuiTablePagination-caption {display: none;}
#mui-51142 {display: block;}


.customOvalBtn {border-radius: 50px !important; min-width:96px!important; font-size: 10px !important;
    padding: 4px 10px !important; font-weight:550!important; margin:0;}
.customOvalBtn svg{font-size:18px;}
.browsingBlock {display: block;
  margin: 24px 160px 0;
  box-sizing: border-box;}
.browsingOptions {padding: 0;display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  background-color: #f0f2f5;
  padding: 0 16px 24px 16px;
}
.activeBrowserOption {background-color: #f0f2f5 !important;}
.centreBtn {border-radius: 99px; position: relative; display: inline-flex; width: 100%; margin-top: 24px; align-items: center; justify-content: center; text-transform: uppercase; font-size: 14px; font-weight: bold; outline: none; border: none; }


.regenerateBlock p {font-size: 12px; color:rgba(0,0,0,0.54);}
.regenerateBlock small{font-size: 12px; color: var(--primaryColor);}
.regenerateBlock button{font-size: 12px;border-radius:50px; text-transform: capitalize; padding: 2px 16px !important;}
/***** Remove Total Count from Table Pagination ********/
.removeTotalCount>div>span:nth-child(4) {display: none !important;}
/*********** BREADCRUMB *****************/
.breadCrumbBlock {
    display: flex;
    flex: 0 0 auto;
    margin-top: 0;
    flex-direction: row;
    align-items: center;
    background-color:#fff; 
    border-bottom:1px solid rgba(0,0,0,0.12);
  }
  .leftTitle{flex: 1 1 auto; padding:0;}
  .rightAction{flex:0 0 auto; padding:0 24px; display: flex; align-items: center;}   
  .rightAction .spanClass {padding: 0 8px; color: rgba(0,0,0,0.54); font-size:14px; }
  .linkBtnGroup {text-decoration: none;}

  .breadCrumbNav {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
  }
  .breadCrumbNav > li {
    display: inline-flex;
    padding: 0;
    margin: 0;
    color: var(--blackInactiveTxt);
    margin-right: 4px;
    align-items: center;
    font-size: 12px;
    padding: 5px 0;
  }
  .breadCrumbNav > li + li:before {
    padding: 0 4px;
    color: var(--blackInactiveTxt);
    content: "/\00a0";
  }
  .breadCrumbNav > li > a {
    display: flex;
    flex: 0 0 auto;
    color: var(--primaryColor);
    font-size: 12px;
    text-decoration: none;
    cursor: pointer;
  }
  .breadCrumbNav > li > .anchorLink{
    display: flex;
    flex: 0 0 auto;
    color: var(--primaryColor);
    font-size: 12px;
    text-decoration: none;
    cursor: pointer;
  }
  .brdCrm24Block {
    display: flex;
    padding: 12px 24px;
    flex-direction: column;
    flex: 0 0 auto;
  }
  /********* End Breadcrumb **********/
/******* Input Fields CSS changed for flexible formation of Forms row-wise - Keerthi ****/
.epFormBlock {display: flex; flex-direction: column; flex: 1;}
.epMainForm {display: flex; flex: 1; flex-direction: column;}
.editFormInput {display: flex; padding: 0; margin: 0; align-items: center;}
.editFormInput>.inputFieldBlock {margin-right:16px;}
.editFormInput>.inputFieldBlock:last-child {margin-right:0;}
.infoTextBlock {display: flex; padding: 4px 0;}
.infoTextBlock > span {font-size: 14px; display: inline-flex; color: var(--blackTxtPrimaryInactive); }
.infoName {color: var(--blackTxtPrimary) !important; margin-left: 4px;}
.infoTextBlock > p {font-size: 16px; display: inline-flex; color: var(--blackTxtPrimaryInactive);}

.customFormControl {margin: 8px 16px 8px 0 !important;; flex: 1; min-width: 152px !important;}
.titleBlock{display: flex; align-items: center; flex: 0 0 auto; padding:0 24px;}
.filterTitleBlock {display: flex; align-items: center; flex: 1 1 auto; padding:0 24px;}
.actionBtn {display: flex; flex: 0 0 auto; padding: 0 24px;}
.customFormGroup {display: flex; padding:16px 0 0; align-items:center;}
.customOutlinedFormControl{margin: 8px 16px 8px 0 !important;; flex: 1; min-width: 152px !important;}
.customOutlinedFormControl>div>div>div, .customOutlinedFormControl input {padding:14px !important;}
/* .customOutlinedFormControl label {line-height: 0.5!important;} */
.noInputBaseWidth>div {width:unset !important;}
/************/
.rightHeaderBlock { display: flex; flex: 0 0 auto; background-color: var(--lightSecondaryGray); position: relative; z-index: 1; height: 56px; align-items: center; padding: 0 24px; box-sizing: border-box; border-bottom: 1px solid rgba(0,0,0,0.12);}
.rightHeaderBlock h2 { display: flex; margin: 0; padding: 0; }


/*************Double Line List****************/
.roomListBlock {flex: 1 1 auto; position: relative; width: 100%; }
.roomListBlock ul {padding: 8px 0; margin: 0; list-style-type: none; display: flex;flex-direction: column; }
.roomListBlock ul li {padding: 0; margin: 0; flex: 1; position: relative;}
.roomListBlock ul li>.listItem {padding: 0; margin: 0; display: flex; align-items: center; position: relative;}
.roomListBlock ul li>.listItem.top {align-items: flex-start !important;}

.colFlex { flex-direction: column; display: flex; width: 100%; flex: 1; }
.colFlex.overFlow { min-height: 0; overflow: hidden; overflow-y: auto;}
.userListGroupContainer {position:relative; display: flex; flex: 1 1 auto; overflow-x: hidden; overflow-y: auto; height: 100%; width: 100%; }
.listItem > .listAvatar { position: relative; margin: 16px; width: 40px; height:40px; border-radius: 50%; flex: 0 0 auto; background-color: var(--inactiveBg); color: var(--whitePrimary);     display: flex; align-items: center;  justify-content: center; }
.listItem > .listAvatar > .statusOnline { position: absolute; z-index: 1; width: 8px; height: 8px; background-color: #1bbf46; border-radius: 50%; bottom: 2px; right: -5px; border: 3px solid #fff; }
.listItem > .listAvatar > .statusOffline { position: absolute; z-index: 1; width: 8px; height: 8px; background-color: #929292; border-radius: 50%; bottom: 2px; right: -5px; border: 3px solid #fff; }
.listItem > .listAvatar small {padding: 0; margin: 0; font-weight: bold; font-size: 14px;}
.listItem > .listAvatar img { width: 100%; height: 100%; border-radius: 50%; object-fit: cover; }
.listItem > .listDetails { padding: 16px 16px 16px 0; border-bottom: 1px solid rgba(0,0,0,0.12); border-bottom: var(--brdrDark); display: flex; align-items: center; width: calc(100% - 80px); box-sizing: border-box; justify-content: space-between; flex: 1 1 100%; }
.listDetails > .listTitleBlock {width: calc(100% - 80px); box-sizing: border-box;}
.listTitleBlock > .listTitle { width: 200px; display: inline-flex; flex-direction: column;}
/* for french*/
.listItem > .fr-listDetails {width: 100%; padding: 16px 16px 16px 0; border-bottom: 1px solid rgba(0,0,0,0.12); border-bottom: var(--brdrDark); display: flex; align-items: center; box-sizing: border-box; justify-content: space-between;  }
.listDetails > .fr-listTitleBlock {width: inherit; box-sizing: border-box; float:1 1 auto;}
.fr-listTitleBlock > .fr-listTitle { width: 160px; display: inline-flex; flex-direction: column;}
.fr-listTitleBlock > .fr-listTitle h3{font-size: 16px; color: var(--blackTxtPrimary); margin: 0; font-weight: 400; text-transform: capitalize; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;}
.fr-listTitleBlock >.fr-listTitle small {text-transform:none; display: flex; color: var(--blackTxtPrimaryInactive); margin-top: 3px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;}
.fr-listTitleBlock >.fr-listTitle small.pndngTxt { color: var(--primaryColor); }
.fr-listTitle a {text-decoration: none; color: var(--primaryColor); font-size: 12px; margin:3px 0 0 16px;}
/* for french*/
.listTitleBlock > .listTitle h3{font-size: 16px; color: var(--blackTxtPrimary); margin: 0; font-weight: 400; text-transform: capitalize; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;}
.listTitleBlock>.listTitle small {text-transform:none; display: flex; color: var(--blackTxtPrimaryInactive); margin-top: 3px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;}
.listTitleBlock>.listTitle small.pndngTxt { color: var(--primaryColor); }
.listTitle a {text-decoration: none; color: var(--primaryColor); font-size: 12px; margin:3px 0 0 16px;}
.roomListBlock > ul > li:last-child > .listItem > .listDetails {border-bottom: none;}
.notuserText {color: #ff0000; font-size: 12px; margin: 0; padding: 0;}
.listActionBlock {flex: 0 0 auto; align-items: center; padding: 0; justify-content: flex-end; display: inline-flex; flex-direction: column; box-sizing: border-box; }
.listActionBlock a  {color: var(--primaryColor); text-decoration: none; font-weight: 550; font-size: 12px;  text-transform: uppercase; padding: 4px 12px; border-radius: 99px; background-color: transparent; border:1px solid var(--primaryColor);}
.listActionBlock a:hover { cursor: pointer; padding: 4px 12px; border-radius: 99px; background: var(--primaryHover); color: var(--whitePrimary);}
.listActionBlock small { font-size: 10px; color: var(--blackTxtPrimaryDisable); margin-top: 3px; text-align: right;}
.linkSmallTxt {margin-top:3px;}
.linkSmallTxt>.renewLink {font-size: 10px; color: var(--blackTxtPrimaryDisable); margin-top: 3px; text-align: right;border: none;
    text-decoration: underline; padding:0;
    text-transform: capitalize;}
.linkSmallTxt>.renewLink:hover {background:unset; padding:0; color:var(--primaryColor); text-decoration: underline; text-transform: capitalize;}
/******** Hand Raise UI *********/
.msgListBox {flex: 1 1 auto; position: relative; }
.handRiseCustomHeading {border-bottom:unset !important;}
.hndRListGroup {padding: 8px 0; margin: 0; list-style-type: none; display: flex;flex-direction: column;}
.hndRList { margin: 0; position: relative; box-sizing: border-box; align-items: flex-start; justify-content: flex-start; text-decoration: none; padding: 16px; display: flex; width:100%; }
.hndRList:after {position: absolute; bottom: 0; content: ""; height: 1px; background-color: rgba(0,0,0,0.12); left: 0; right: 0; }
.hndRAvtr {width: 40px; height: 40px; display: flex; align-items: center; justify-content: center; overflow: hidden; flex-shrink: 0; user-select: none; box-sizing: border-box;border: 1px solid #ddd;
    border-radius: 50%; margin-right:16px;}
.hndRAvtr img {width: 24px; height: auto; }
.hndRListInfo { display: flex; flex: 1; padding-right: 16px; min-width: 0; box-sizing: border-box; }
.hndRListHdng { color: rgba(0,0,0,0.87); font-size: 1.0rem; margin: 0; display: block; box-sizing: border-box; white-space: normal; word-break: break-word; }
.hndRListHdng small { font-size: 14px; color: rgba(0,0,0,0.54); line-height: 20px; margin-left: 4px; }
.hndRActionBlock { display: flex; flex-direction: column;  position: relative; box-sizing: border-box; justify-content: center; min-width:56px; align-items: flex-end; }
.hndRActionBlock .hrTime{font-size: 12px;
    /* text-transform: uppercase; */
    color: rgba(0,0,0,0.38);
    text-align: right;
    margin-bottom: 6px;}
.hndRActionBlock .hrActionCalls { display: flex;
    flex: 1;
    align-items: center;}
.hrActionCalls span{ display: inline-flex;
    height: 24px;
    width: 24px;
    padding: 0 0 0 16px;
    cursor: pointer;
    filter: grayscale(100%);
    transition: all 0.3s ease;
}

.hrActionCalls span:hover { filter: grayscale(0%);}
.activehrAction > span {filter: grayscale(0%)!important;}
.hndRAskList {background-color:var(--whiteBg); border-bottom:var(--brdrDark);  margin: 0; position: sticky; top:0; box-sizing: border-box; align-items: flex-start; justify-content: flex-start; text-decoration: none; padding: 16px; display: flex; z-index: 5;}
/***** 8th April 2020 - Keerthi - For Hand Rise *****/
.hndRUserAvatar {width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #ddd;
  margin-right: 16px;
  display: flex;}
.slefBg {background-color: var(--lightGray);}

/* .msgListBox ul li:last-child{border:none;}
.msgListBox ul li>.msgListItem {padding: 0; margin: 0; display: flex; align-items: center; overflow: hidden; position: relative; border-bottom:1px solid rgba(0,0,0,0.12);}
.msgAvatar {flex: 0 0 auto; padding:16px; width: 24px; height: 24px;}
.msgAvatar img {width: 100%; height:100%;}
.msgDetailBlock {flex: 1 1 auto; padding: 0;}
.msgDetailBlock>.nameTxt {font-size: 16px; color: rgba(0,0,0,0.87); text-transform: capitalize; font-weight: 400;}
.msgDetailBlock p {font-size: 14px; color: rgba(0,0,0,0.54); margin:0;}
.msgInfoBlock {flex:0 0 auto;display: flex; flex-direction: column; padding: 16px; align-items: center;}
.msgInfoBlock>.msgTimer {flex: 0 0 auto;}
.msgInfoBlock>.msgTimer span {font-size:14px; color: rgba(0,0,0,0.54);}
.msgInfoBlock>.msgAction {display:flex; flex: 0 0 auto;} */



/***************/
.flexActionBlock {flex: 0 0 auto; align-items: center; padding: 0; justify-content: flex-end; display: inline-flex; box-sizing: border-box; }
.txtNowrap {white-space: unset!important; line-height: 20px;}

.anchorLink {color: var(--primaryColor); margin-left: 8px; }

.iconBox { cursor: pointer; width: 40px; height: 40px; box-sizing: border-box; display: flex; align-items: center; justify-content: center; border-radius: 50%;box-sizing: border-box; align-self: flex-end; margin-right:8px; }
.iconBox:last-child {margin-right:0;}
.iconBox > img { width: 24px; height: 24px; filter: grayscale(100%); -ms-transition: all 0.3s ease; -moz-transition: all 0.3s ease; -webkit-transition: all 0.3s ease; transition: all 0.3s ease;  }
.iconBox > img:hover { filter: grayscale(0%);}
.ActiveiconBox > img {filter: grayscale(0%)!important;}
.iconBox button {padding:8px!important;}
.imageIconBox { cursor: pointer; width: 44px; height: 44px; box-sizing: border-box; display: flex; align-items: center; justify-content: center; border-radius: 50%;box-sizing: border-box; align-self: center; margin-right:8px; }
.imageIconBox:last-child {margin-right:0;}
.imageIconBox > img { width: 20px; height: 20px; -ms-transition: all 0.3s ease; -moz-transition: all 0.3s ease; -webkit-transition: all 0.3s ease; transition: all 0.3s ease; }
.imageIconBox:hover {background-color: rgba(89,186,239,.08); border-radius:50px;}

/* .imageIconBox {width:40px; height:40px;}
.imageIcon {width:24px; height:24px; cursor: pointer; padding:12px;}
.imageIcon img {width:100%; height:100%;}
.imageIcon:hover {background-color:rgba(89,186,239,.08); padding:12px; border-radius:50px;} */

.customIconBtn { cursor: pointer; width: 40px; height: 40px; box-sizing: border-box; display: flex; align-items: center; justify-content: center; border-radius: 50%;box-sizing: border-box; align-self: center;  }
.customIconBtn > img { width: 20px; height: 20px; filter: grayscale(100%); -ms-transition: all 0.3s ease; -moz-transition: all 0.3s ease; -webkit-transition: all 0.3s ease; transition: all 0.3s ease; }
.customIconBtn:hover> img{ filter: grayscale(0%); border-radius: 50%; }
.customIconBtn:hover {background-color: rgba(89, 186, 239,0.08);}
/******** For Icons *******/
.hoverIconBtn > svg { width: 20px; height: 20px; filter: grayscale(100%) !important; -ms-transition: all 0.3s ease; -moz-transition: all 0.3s ease; -webkit-transition: all 0.3s ease; transition: all 0.3s ease; }
.hoverIconBtn:hover svg{ fill:var(--primaryColor) !important; border-radius: 50%; }
.hoverIconBtn:hover {background-color: rgba(89, 186, 239,0.08) !important;}
/************/

.smallIconButton {fill:rgba(0,0,0,0.38) !important; padding:3px !important; margin-left:8px !important;font-size: 14px !important;}
.smallIconButton svg {font-size: 14px !important;}
.smallIconButton:hover{fill:var(--primaryColor) !important; cursor: pointer;}

.flipIcon {transform: rotateY(180deg);}
.alignflexEnd {display:flex; align-items: flex-end !important;}
/********* Static Input field *********/
.nobrdr {border:none !important;}
.noBackground {background-color: transparent !important;}
.inputLimit {display: flex; align-items: center;padding: 15px 16px;
    background-color: #f6f7fa;
    box-sizing: border-box;
    height: 56px;}
.inputLimit span {flex: 1 1 auto; font-size: 16px;
    color: #336ebf;
    font-weight: 450;}
.limitCtrl {flex:0 0 auto; border: 1px solid rgba(0,0,0,0.12);
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 8px;
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    justify-content: space-evenly;}
.limitIcon {flex:0 0 auto; display: flex; background-color:#dfdfdf; }
.limitIcon:hover svg{fill:#336ebf !important; cursor: pointer; font-size: 20px; background-color:#b3dceb; }
.inputLimitValue {flex: 1 1 auto; padding: 0 8px; }
.limitIcon svg {fill:#fff; font-size: 20px;}

/*****************/

/***** Link Tag *****/
.contenTxt { display: flex; flex: 0 0 auto; align-items: center; justify-content: center;}
.contenTxt > p { display: inline-flex; padding: 0 16px 0 0; margin: 0; color: var(--blackTxtPrimary) }
.contenTxt > a { display: inline-flex; text-decoration: none; color: var(--primaryColor); }
.contenTxt > a:hover { position: relative;}
.contenTxt > a:hover:after { position: relative; content: "\bb"; margin-left: 4px; }

/***** Link Tag *****/
.errorLink { display: block; align-items: center; justify-content: center; margin-bottom: 8px;}
.errorLink > span { display: inline-block; font-size: 12px; padding: 0 16px 0 0; margin: 0; color: #f44336; }
.errorLink > span > a { display: inline-block; text-decoration: none; color: var(--primaryColor); }
.errorLink > span > a:hover { position: relative;}
.errorLink > span > a:hover:after { position: relative; content: "\bb"; margin-left: 4px; }

/**** start - Video Stream Screen CSS *****/
.screenContainer { display: flex; flex: 1 1 auto; position: relative; height: 100%; width: 100%;  z-index: 1;  }
/* .screenContainer:-webkit-full-screen { width: 100%; height: 100% !important;}
.screenContainer:-moz-full-screen { width: 100%; height: 100% !important;}
.screenContainer:fullscreen { width: 100%; height: 100% !important;} */

/* .screensVideoContainer { width: 100%; height: 100%; position: relative; display: flex; background-color: #191919; align-items: center; } */
.screenRow { display: inline-flex; flex: 1; background-color: #ff0000; }

/***Duplicate of Screen Container *********/
.confScreenContainer { display: flex; flex: 1 1 auto; position: relative; height: 100%; width: 100%;  z-index: 1;  }


.sixteen9 { width: 100%; height: 0; padding-bottom: 56.25%; background-color: #272727; position: relative; overflow: hidden; display: flex; align-items: center; justify-content: center; }
.sixteen9 > img,
.sixteen9 > video { position: absolute; z-index: 1; width: 100%; object-fit: cover; top: 0; left: 0; height: 100%; }
.sixteen9 > .pName { position: absolute; z-index: 2; left: 0; top: 0; background-color: rgba(0,0,0,0.5); padding: 8px 16px; color: #fff; font-size: 0.8rem; }
/**** end - Video Stream Screen CSS *****/

/****** Broadcast Streaming ********/
.bgbcImage {background-image: url("/images/bgbc-trans.png"); background-color: #f7f8fa;}
/* .bdCenterBlock { position: relative;  display: flex; align-items: center; justify-content: center; } */
.bdRatioBlock { position: relative; display: flex; align-items: center; justify-content: center; height: calc(100vh - 144px); background-color: transparent;
width: calc(calc(100vh - 144px) * calc(16/9)); /*width: calc(calc(100vh - 144px) * 1.77777778);*/ }

/* FOR 4/3 VIDEOS */
.bdRatioBlock4-3 { position: relative; display: flex; align-items: center; justify-content: center; height: calc(100vh - 144px); background-color: transparent; width: calc(calc(100vh - 144px) * calc(4/3)); }

/* FOR 16/9 VIDEO */
.broadcastVideoBlock { width: 100%; height: 0; padding-bottom: 56.25%; margin:0; background-color: #000; position: relative; overflow: hidden;}
/* FOR 4/3 VIDEO */
.broadcastVideoBlock4-3 { width: 100%; height: 0; padding-bottom: 75%; margin:0; background-color: #000; position: relative; overflow: hidden;}

.bdVideoFrame {position: absolute; top: 0; left: 0; bottom: 0; right: 0; text-align: center; overflow: hidden; display: block; -ms-flex-align: center; align-items: center; -ms-flex-pack: center; justify-content: center; }
.bdVideoFrame > img,
.bdVideoFrame > iframe { width: 100%; object-fit: cover; height: 100%; border:none; }

/***** New Class Only for Video Sync Added in Player Component - By Keerthi ***/
.syncVideoFrame {position: absolute; top: 0; left: 0; bottom: 0; right: 0; text-align: center; overflow: hidden; display: block; -ms-flex-align: center; align-items: center; -ms-flex-pack: center; justify-content: center; }
.syncVideoFrame > img,
.syncVideoFrame iframe { width: 100%; height: 100% !important; border:none; }








/* .videoPlayerBlock { position: relative; display: flex; align-items: center; justify-content: center; 
  height: calc(100vh - 80px);
  background-color: #000;
  width: calc(calc(100vh - 80px) * 1.77777778);} */

.videoPlayerBlock {position: relative; display: flex; align-items: center; justify-content: center; 
  height: auto;
  background-color: #000;
  width: 100%;}

/*********** Labels **************/
.freeLabel {background-color: #FFB800; font-weight: 550;
    padding: 4px 8px;
    border-radius: 50px;
    text-align: center;
    color: #fff !important;
    font-size: 10px; margin-left: 8px;}
.pocLabel {background-color: #79CDF5; font-weight: 550;
    padding: 4px 8px;
    border-radius: 50px;
    text-align: center;
    color: #fff !important;
    font-size: 10px; margin-left: 8px;}
.commercialLabel {background-color: #0090d2; font-weight: 550;
    padding: 4px 8px;
    border-radius: 50px;
    text-align: center;
    color: #fff !important;
    font-size: 10px; margin-left: 8px;}
/* .extraLabel {background-color:#51baa3; font-weight: 550;
    padding: 4px 8px;
    border-radius: 50px;
    text-align: center;
    color: #fff !important;
    font-size: 10px; margin-left: 8px;} */

    .extraLabel {background-color: #f5f5f5;
        font-weight: 550;
        padding: 2px 4px;
        border-radius: 50px;
        text-align: center;
        /* color: #fff !important; */
        font-size: 10px;
        margin-left: 8px;
        margin: 0;}

.freeTxt {color:#FFB800;}
.pocTxt{color:#79CDF5;}
.commercialTxt { color:#0090d2;}

/**************************/
/********* Footer Buttons **********/
.btnBothBlock {display: flex; justify-content: flex-end; padding: 0; align-items: center;}
.btnLeftBlock {margin-right: 16px;display: flex; }
.btnRightBlock {display: flex;}

.btnPrmOutln {border-radius: 99px !important; height: 32px; line-height: 1.2 !important;}
.btnPrmFilled {box-shadow: none !important; border-radius: 99px !important; height: 32px;}
.btnPrmFilled:hover {background: var(--primaryHover); }
.btnPrmLightFilled {box-shadow: none !important; border-radius: 99px !important; height: 32px; background-color: var(--primaryLight) !important}

.btnPrmFilled.lg {height: 48px;}
.btnPrmFilled.lg:hover {background: var(--primaryHover); }
.btnPrmOutln.lg{height: 48px;}

.bmTopBlock {display: flex; margin-bottom: 16px; align-items: center; flex: 0 0 auto;}
.backLink {text-decoration: none; color: var(--primaryColor); display: flex; align-items: center; padding: 8px 16px;}
.backLink:hover {background-color: rgba(89, 186, 239,0.08); border-radius: 99px; padding: 8px 16px;}

/********* Material Slider Custom ***********/

.sliderClass {padding: 22px 0;}
.sliderClass button {width: 20px; height: 20px;}

.rowBlock {display: flex;flex: 1;position: relative;overflow-x: hidden;overflow-y: auto;}
.mainHeading {margin: 0; padding: 0; font-size: 1.5rem; font-weight: 400;}
.subHeading {font-size: var(--subHeading); color: var(--blackTxtPrimary); font-weight: 400}
.font14Txt { font-size: 14px; margin: 0; padding: 0; font-weight: 400; color: var(--blackTxtPrimaryInactive);}
.titleTxt16 {font-size: 16px !important; margin: 0 0 8px 0; padding: 0; font-weight: 550; color: var(--blackTxtPrimaryInactive);}

.confirmationTxt {font-size: 14px; font-weight: 400; padding: 0; margin:0 0 16px 0; color: var(--blackTxtPrimaryInactive); }
.noteBlock {border-top:var(--brdrDark); }
.noteHeading {font-size: 0.875rem; margin:8px 0;}
.notesmallTxt {font-size: 12px;font-weight: 400;
  color: var(--blackTxtPrimaryInactive);
  margin: 0;}

.mainLayout { display: flex; flex:1; position: relative; height: inherit; }
.mainContainer { display: flex; position: relative; flex: 1; top: 64px; height: calc(100% - 64px); width:100%; }
.removeTop {top:0 !important; height: 100vh !important;}
.innerContentContainer { display: flex; position: relative; flex: 1; flex-direction: column; width: 100%; } 
.container1024 { display: flex; flex-direction: column; margin: 16px auto; width: 1024px; border-radius: 4px; padding: 16px 0; position: relative;} 
.container1140 { display: flex; flex-direction: column; margin: 16px auto; width: 1140px; border-radius: 4px; position: relative;} 
/* start CSS for Headings */
h2 { font-size: 1.25rem; font-weight: 400; color: var(--blackTxtPrimary); }
/* end CSS for Headings */

/* start CSS for Links */
a { font-size: 14px; }
p { font-size: 14px; }
button { outline: none; cursor: pointer;}

.block320 {width: 320px;}
.block160 {width: 160px;}


.circle { border-radius: 50%; position: relative; box-sizing: border-box; }
.circle > img { border-radius: 50%; height: 100%; width: 100%; }
.circleXsm { width: 32px; height: 32px; }
.circleSm { width: 40px; height: 40px; }
.circleMd { width: 56px; height: 56px; }
.circleLg { width: 72px; height: 72px; }
.selectColor {width: 24px; height: 24px; cursor: pointer;}

/* start CSS for Buttons */
.btn { min-width: 64px; height: 32px; border-radius: 99px; border: 1px solid #336ebf; background-color: transparent; font-size: 14px; font-weight: 450; color: var(--primaryColor); text-transform: uppercase;}
.btnSm { height: 32px; }
.btnM { height: 40px; }
.btnL { height: 56px; }
.btn:hover { background: var(--primaryHover); color: #fff; box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);}

.floatingCircle { z-index: 1; position: absolute; right: 16px; bottom: 16px; align-items: center; justify-content: center; display: flex; font-size: 32px; color: #fff; box-sizing: border-box; cursor: pointer; }
.floatingBtnBg {background-color: var(--secondaryBg) !important;}
.floatingBtnBg:hover {background-color: var(--secondaryDarkBg) !important;}
.badgePosition span{top:8px !important; right:8px !important;}

.floatingJoinBtn { display:none;}
.floatingBtnBg {background-color: var(--secondaryBg) !important;}
.floatingBtnBg:hover {background-color: var(--secondaryDarkBg) !important;}

.addOvalBtn { min-width: 40px; border-radius: 99px !important; background-color: var(--primaryColor); color: #fff; color: var(--whitePrimary); font-size: 14px; text-transform: uppercase; font-weight: 550; padding: 4px 12px; display: flex; align-items: center; justify-content: center; cursor: pointer; }
.addOvalBtn:hover { background: var(--primaryHover); }
.addOvalBtn.pndngApproval { background-color: #c9c9c9; }
.addOvalBtn.pndngApproval > img { width: 16px; height: 16px; display: flex; }
/* end CSS for Buttons */

/* start CSS for serach block */
.innerSearchBlock { margin: 16px 0; box-sizing: border-box; display: flex; height: 48px; position: relative; flex: 0 0 auto; align-items: center; justify-content: center; }
.searchBlock { box-sizing: border-box; width: 100%; display: flex; height: 48px; position: relative; flex: 0 0 auto; padding: 8px 16px; align-items: center; justify-content: center;}
.searchBlock > input[type=search], .innerSearchBlock > input[type=search] { position: relative; -moz-appearance: none; -ms-appearance: none; -webkit-appearance: none; border: none; border-right: 0; font-size: 14px; color: var(--blackTxtPrimaryInactive);  height: 32px; width: 100%; padding: 0 8px 0 48px; line-height: 1; text-align: left; outline: 0; border-radius: 99px; background-color: var(--searchBg); }
.searchBlock > .searchIcon, .innerSearchBlock > .searchIcon { z-index: 1; position: absolute; width: 32px; height: 32px; left: 24px; display: flex; align-items: center; justify-content: center;}
.searchBlock > .searchIcon img, .innerSearchBlock > .searchIcon img { width: 18px; height: 18px; opacity: 0.38; }
.innerSearchBlock > .searchIcon { left: 16px; justify-content: flex-start; }
/* end CSS for serach block */
/**** Additional Class for Search Block - 8th April 2020 - Keerthi *******/
.hndSearchBlock {height: unset; padding:12px 16px; border-bottom: var(--brdrDark);}
.toggleBlock {height: unset; padding:8px 16px; border-bottom: var(--brdrDark); background-color:#fafafa;}
.toggleLabelTxt {color:var(--blackTxtPrimary); display:flex; flex:1;}
/***********/
/******* Promo Code *******/
.packagePromoBlock {position: relative;
    right: 0;
    top: 0; }
.packagePromoBlock input {min-width: 304px;}
.packagePromoBtnFilled {cursor: pointer;}
.packagePromoBtnFilled svg{fill:var(--primaryColor);}

 /*****Search Info Block Details ****/
.searchInfoBlock {left: 0;
    right: 0;
    z-index: 1;
    position: relative;
    margin: 0 0 16px;
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
    background-color: #fff;
    box-sizing: border-box;
}
.searchInfoBlock ul {list-style-type: none; padding: 8px 0; margin: 0;}
.searchInfoBlock ul li {list-style-type: none; display: flex;}
.searchInfoBlock ul li .searchListItem:hover { text-decoration: none;
    background-color: rgba(0, 0, 0, 0.08);}
.selectedSearchItem {background-color: rgba(0, 0, 0, 0.08);}
.searchInfoBlock ul li .searchListItem {padding: 0; margin: 0; width:100%; display: flex; align-items: center; position: relative; flex:1;}
.searchListItem>.searchUserAvatar{position: relative; margin: 16px; width: 40px; height:40px; border-radius: 50%; flex: 0 0 auto; background-color: var(--inactiveBg); color: var(--whitePrimary); display: flex; align-items: center;  justify-content: center; }
.searchListItem>.searchUserAvatar img { width: 100%; height: 100%; border-radius: 50%; }
.searchListItem>.searchUserDetails { padding: 12px 16px 12px 0; border-bottom: 1px solid rgba(0,0,0,0.12); border-bottom: var(--brdrDark); display: flex; align-items: center; width: calc(100% - 72px); height:72px; box-sizing: border-box; justify-content: space-between; }
.searchInfoBlock ul li:last-child>.searchListItem .searchUserDetails {border-bottom:none;}
.searchUserDetails > .searchUserTitleBlock {width: calc(100% - 48px); box-sizing: border-box;}
.searchUserTitleBlock > .searchUserTitle { width: 200px; display: inline-flex; flex-direction: column;}
.searchUserTitleBlock > .searchUserTitle h3{font-size: 16px; color: var(--blackTxtPrimary); margin: 0; font-weight: 400; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;}
.searchUserTitleBlock>.searchUserTitle small { display: flex; color: var(--blackTxtPrimaryInactive); margin-top: 3px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;}
.searchUserTitleBlock>.searchUserTitle small.pndngTxt { color: var(--primaryColor); }

.searchUserActionBlock {flex: 0 0 auto; align-items: center; padding: 0; justify-content: flex-end; display: inline-flex; flex-direction: column; box-sizing: border-box; }
.searchUserActionBlock a  {color: var(--primaryColor); text-decoration: none; font-weight: 550; font-size: 12px;  text-transform: uppercase; padding: 4px 12px; border-radius: 99px; background-color: transparent;}
.searchUserActionBlock a:hover { cursor: pointer; padding: 4px 12px; border-radius: 99px; background: var(--primaryHover); color: var(--whitePrimary);}
.searchUserActionBlock small { font-size: 10px; color: var(--blackTxtPrimaryDisable); margin-top: 3px; text-align: right;}
/** End Of Search Info Block **/

/******** SMALL Width Search *******/

.searchListItem>.smallUserAvatar32{position: relative; margin: 8px; width:32px; height: 32px;  border-radius: 50%; flex: 0 0 auto; background-color: var(--inactiveBg); color: var(--whitePrimary); display: flex; align-items: center;  justify-content: center; }
.searchListItem>.smallUserAvatar32 img { width: 100%; height: 100%; border-radius: 50%; }
.searchListItem>.smallUserDetails { padding: 12px 16px 12px 0; border-bottom: 1px solid rgba(0,0,0,0.12); border-bottom: var(--brdrDark); display: flex; align-items: center;height:56px; box-sizing: border-box; justify-content: space-between;  width:calc(100% - 48px);}
.searchUserTitleBlock > .smallUserTitle h3 {font-size:14px; color: var(--blackTxtPrimary); margin: 0; font-weight: 400; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;}
.searchUserTitleBlock > .smallUserTitle  {width: unset!important;}
.searchUserTitleBlock > .smallUserTitle small { display: flex; color: var(--blackTxtPrimaryInactive); margin-top: 3px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;}
.searchInfoBlock ul li:last-child>.searchListItem .smallUserDetails {border-bottom:none;}
/******* Set Host For ROOM ***********/
.floatingSearchList {height:0; z-index:2; position:absolute; margin: 0 0 16px;
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
    background-color: #fff;
    box-sizing: border-box; width:calc(100% - 16px); overflow-x: hidden;
    overflow-y: auto;
    transform: translateY(8px);
    transition: all 0.3s ease;
}
.slideDown {transform: translateY(-40px);
    transition: all 0.3s ease;
    max-height: 216px !important;
    height: unset; top:96px; }

.floatingSearchList ul {list-style-type: none; padding: 8px 0; margin: 0;}
.floatingSearchList ul li {list-style-type: none; display: flex;}
.floatingSearchList ul li .searchListItem:hover { text-decoration: none;
    background-color: rgba(0, 0, 0, 0.08);}
.selectedSearchItem {background-color: rgba(0, 0, 0, 0.08);}
.floatingSearchList ul li .searchListItem {padding: 0; margin: 0; width:100%; display: flex; align-items: center; position: relative; flex:1;}

.limitedSearchHeight {max-height:288px; overflow-x: hidden; overflow-y: auto;}
.selectedUserBlock {left: 0;
    right: 0;
    z-index: 1;
    position: relative;
    margin: 24px 0 16px;
    box-sizing: border-box;
}
.selectedUserBlock p {padding: 0 8px;}
.selectedUserBlock ul {list-style-type: none; padding: 8px 0; margin: 0;}
.selectedUserBlock ul li {list-style-type: none; display: flex;}
.selectedUserBlock ul li .selectUserListItem:hover { text-decoration: none;
    background-color: rgba(0, 0, 0, 0.08);}
.selectedSearchItem {background-color: rgba(0, 0, 0, 0.08);}
.selectedUserBlock ul li .selectUserListItem {padding: 0; margin: 0; width:100%; display: flex; align-items: center; position: relative; flex:1;}
.selectUserListItem>.selectUserAvatar{position: relative; margin: 16px; width: 40px; height:40px; border-radius: 50%; flex: 0 0 auto; background-color: var(--inactiveBg); color: var(--whitePrimary); display: flex; align-items: center;  justify-content: center; }
.selectUserListItem>.selectUserAvatar img { width: 100%; height: 100%; border-radius: 50%; }
.selectUserListItem>.selectUserInfoDetails { padding: 12px 16px 12px 0; border-bottom: 1px solid rgba(0,0,0,0.12); border-bottom: var(--brdrDark); display: flex; align-items: center; width: calc(100% - 72px); height:72px; box-sizing: border-box; justify-content: space-between; }
.selectUserActionBlocks {flex: 0 0 auto; align-items: center; padding: 0; justify-content: flex-end; display: inline-flex; box-sizing: border-box;}
.selectUserActionBlocks button {}
.selectedUserBlock ul li:last-child>.selectUserListItem .selectUserInfoDetails {border-bottom:none;}
.selectUserInfoDetails > .selectUserTitleBlock {width: calc(100% - 48px); box-sizing: border-box;}
.selectUserTitleBlock > .selectUserTitle { width: 200px; display: inline-flex; flex-direction: column;}
.selectUserTitleBlock > .selectUserTitle h3{font-size: 16px; color: var(--blackTxtPrimary); margin: 0; font-weight: 400; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;}
.selectUserTitleBlock>.selectUserTitle small { display: flex; color: var(--blackTxtPrimaryInactive); margin-top: 3px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;}
.selectUserTitleBlock>.selectUserTitle small.pndngTxt { color: var(--primaryColor); }
/** End Of Select Host Info Block **/

.errorMsg { display: flex; flex: 0 0 auto; flex-direction: column; padding: 8px 0;}
.reqMsg { display: flex; flex: 1; align-items: center; padding: 8px 0}
.errorMsg p, .reqMsg p { margin: 0; }
.errorTxt {color: #f44336;}
.errorTxt p {font-size: 12px; margin:0px; font-size: 0.75rem;}

/******** Selected Chips *******/
.chipListGroup { display: flex; flex-direction: column; justify-content: center; padding: 16px 16px 8px; background-color: var(--chipBg); border-radius: 4px;}
.chipListBlock {display: flex; flex-wrap: wrap;}
.chipListBlock > ul {list-style-type: none; padding: 0; margin: 0; display: flex; flex-wrap: wrap;}
.chipListBlock > ul >li {list-style-type: none; padding: 0; margin: 0 8px 8px 0; display: flex;}


/**** TAB NAV CSS ***/
.tabNav { display: flex; flex: 0 0 auto; background-color: var(--lightSecondaryGray); position: relative; z-index: 1; height: 56px; top: 0; left: 0; right: 0; align-items: center; padding: 0 16px; box-sizing: border-box;}
.tabNav ul { display: flex; margin: 0; padding: 0; list-style-type: none; }
.tabNav ul li { display: inline-flex; position: relative; }
.tabNav ul li a { display: flex; padding: 15px 16px; margin: 0; text-transform: uppercase; text-decoration: none; border-top: 3px solid transparent; border-bottom: 3px solid transparent; color: var(--blackTxtPrimaryInactive); font-weight: 450;}
.tabNav ul li a:hover { border-bottom: 3px solid var(--primaryLight); color: var(--primaryColor); }
.tabNav ul li a.activeTabNav { border-bottom: 3px solid var(--primaryColor); color: var(--primaryColor); }
.tabNavResponsive {display: none;}
/*** LIST GROUP ***/
.listGroupContainer { display: flex; flex: 1; flex-direction: column; position: relative; }
.listHeader { display: flex; flex: 0 0 auto; height: 56px; box-sizing: border-box; border-bottom: 1px solid rgba(0,0,0,0.12); align-items:center; }
.listBody { display: flex; flex: 1 1 auto; overflow-y: auto; box-sizing: border-box; padding: 16px 24px; position: relative;}

/********* Loading Component ************/
.loadingContainer{ position: absolute; z-index: 99999; top: 0; right: 0; bottom: 0; left: 0;}
.loadingBlock {display: flex; height: 100%;  align-items: center;  justify-content: center; position: relative; z-index: 999999;}
.loadingClass {display: flex;align-items: center;justify-content: center;height: 100%;}
.centreLoading {height: auto; width: auto; text-align: center;}
.noScheduleBlock {position: relative; width: 100%; flex:1; display: flex;}
.centreMsgBlock {position: relative; width: 100%; display: flex; z-index: 998; flex:1;}
/******** Dialog Component ********/

.dialogMainBlock {}
.dialogTitleBlock {display:flex; flex:0 0 auto; border-bottom: 1px solid rgba(0, 0, 0, 0.12); padding: 16px !important;
    margin: 0;}
.dialogHeaderBlock {display: flex; flex-direction: row; align-items:center; }
.dialogHeaderText {display: flex; align-items: center; flex: 1 1 auto;}
.dialogHeaderText h3{color: rgba(0,0,0,0.87); font-size: 1.25rem; font-weight: 400; line-height: 1.6; letter-spacing: 0.0075em;}
.dialogHeaderAction {display: flex; align-items: center;justify-content: center;flex: 0 0 auto; top: 8px;
    right: 8px;
    position: absolute;}
.dialogContentBlock {display: block; padding: 16px !important; margin:0;}
.dialogContentInner {display: flex; flex: 1;}
.dialogFooterBlock {display: flex !important; flex:0 0 auto; margin: 0 !important; padding: 8px; border-top: 1px solid rgba(0, 0, 0, 0.12); justify-content: flex-end;}
.dialogContent508 {min-width: 508px !important;}
.dialogContent960 {min-width: 960px !important;}
.dialogVideo div>div {width:calc(calc(100vh - 96px) * 1.77777778);}
.dialogaction3Btns {display: flex; align-items: center;}
.dialogActionLeftBtn {flex:1; display: flex;}

.dialogFullWidth >div >div {width: 100% !important;}
.dialogTitle {display: flex; align-items:center;}
.dgInnerBlock {display: flex; padding-top: 24px; align-items: center; justify-content: center; }
.dialogIcon {width: 64px; height: 64px; display: flex; align-items: center; justify-content: center; background-color: orange; padding: 16px;}
.dialogIcon img {width:100%; height:100%;}
.dialogIconTxt {display: flex; flex-direction: column; justify-content: center; padding: 0 24px;}
.dialogIconTxt h3 { font-size: 16px; margin: 0; padding: 0; font-weight: 550; margin-bottom: 8px;}
.dialogIconTxt p { font-size: 14px; margin: 0; padding: 0;color: rgba(0,0,0,0.54);}
.dialogBtnBlock {display: flex; align-items: center;}
.dialogBtnLink {min-width: 64px; height: 32px; border-radius: 99px; background-color: #336ebf; font-size: 14px; font-weight: 450; color: #ffffff; display: flex; align-items: center; justify-content: center; text-decoration: none; padding: 0 16px;}
.dialogBtnLink:hover {background-color: var(--primaryDark);}

.dgInfoBlock {display: flex; flex-direction: column; align-items: center; justify-content: center; margin-top:16px; }
.dialogInfoIcon {width: 196px; height: auto; display: flex; align-items: center; justify-content: center; padding: 16px; margin-bottom: 16px;}
.dialogInfoIcon img {width:100%; height:100%;}
.dialogInfoIconTxt {display: flex; flex-direction: column; justify-content: center; padding: 0 24px; align-items: center;}
.dialogInfoIconTxt h3 { font-size: 16px; margin: 0; padding: 0; font-weight: 550; margin-bottom: 8px;}
.dialogInfoIconTxt p { font-size: 14px; margin: 0; padding: 0;color: rgba(0,0,0,0.54);}
.dialogInfoAction {justify-content: center !important; margin-bottom:16px !important;}


/******* Conference Ststs Info *******/
.statsDialogTitle {background-color: #000;
    border-bottom: 1px solid rgba(255,255,255,0.12);}
.statsDialogHeader {display: flex; align-items: center; flex: 1 1 auto;}
.statsDialogHeader h3{color: rgba(255,255,255,0.87);; font-size: 1.25rem; font-weight: 500; line-height: 1.6; letter-spacing: 0.0075em;}
.statsDialogHeaderAction {display: flex; align-items: center;justify-content: center;flex: 0 0 auto; top: 8px;
    right: 8px;
    position: absolute;}
.statsDialogHeaderAction button {color:#fff;}
.statsInfoDetails {flex: 1 1 auto; padding: 0; display: flex; flex-direction: row;}
.statsDividedContent {display:flex; flex:1; overflow: hidden; margin: 0 16px;
    flex-direction: column;}
.statsDialogContent { padding:0 !important;}
.statsDividedHeader {flex:0 0 auto; text-align: center; display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;}
.statsDividedHeader h2{font-size: 16px;
    font-weight: 500;
    color: rgba(255,255,255,0.87);
    margin: 16px 0;}
.dialogInner {display:flex; flex-direction: column; flex:1;}


.dialogInfoDetails {flex: 1 1 auto; padding: 16px 0 0; display: flex; flex-direction:column; }
.dialogBlock50 {flex:0 0 auto; border-right:1px solid rgba(255,255,255,0.08); display: flex; flex-direction: column; padding:0; box-sizing: border-box;}
.dialogBlock50:last-child { border-right: none;}
.verticalDivider { margin:0 16px;}
.dialogContentHeader {flex: 0 0 auto; text-align: center; padding:16px 32px; background-color: #000; color:rgba(255,255,255,0.87); font-weight:550;}
.dialogInfoProperties {display:flex; flex: 1 1 auto; padding:4px 32px;  padding-bottom:48px;  overflow: hidden !important;}
.dividedContent {display:flex; flex:1; overflow: hidden; background-color: #fff !important; margin: 16px 0;
    min-width: 480px;}

.dividedHeader {flex:0 0 auto; text-align: center; display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; width:128px;}
.dividedHeader h2{font-size: 16px;
    font-weight: 500;
    color: rgba(255,255,255,0.87);
    margin: 16px 0;}
.dividedContent>.dividedInfo {flex:1 1 auto; padding:16px;}
.dividedIcon {width: 40px; height: 40px;}
.dividedIcon img {width: 100%; height: 100%;}
.dividedInfo>ul {padding: 0; margin: 0; list-style-type: none;}
.dividedInfo>ul>li {display: flex; list-style-type: none; margin-bottom:8px;}
.dividedInfo>ul>li .txtProp {flex:1; color: rgba(255,255,255,0.54); font-size:14px; min-width:120px;}
.dividedInfo>ul>li .txtValue {flex:1; color: rgba(255,255,255,0.87); font-size:14px;}
.blackDialogOverlay>div>div{background-color: rgba(0,0,0,0.8) !important; z-index:2; border: 1px solid rgba(255,255,255,0.12);}

.lightBGDialog{background-color: #fafafa !important;}
.dialInIcons {width:80px; height:56px;}
.dialInIcons img {width: 100%; height: 100%;}
.dividedInfo h3 {font-size: 16px; font-weight: 500; color: rgba(0,0,0,0.87); margin-bottom: 8px;}
.copyText {width: 100%;}
.copyText input{color: var(--primaryColor)}
.copyButtonIcon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 8px;
    margin:0; cursor: pointer;
}
.copyButtonIcon:hover {background-color:unset !important; cursor:pointer;}
.copyButtonIcon img {width: 20px; height: 20px; cursor:pointer;}
.nakedInput {}
/********* ScreenShare Access *******/
.answerRequestDialog {display: flex;
    align-items: center;
    justify-content: center; margin-top:16px;}
.answerRequestIcon {width: 196px; height: auto; display: flex; align-items: center; justify-content: center;}
.answerRequestIcon img {width:100%; height:100%;}
.answerRequestTxt {display: flex; flex-direction: column; justify-content: center; padding-left:24px; align-items: center;}
.answerRequestTxt h3 { font-size: 16px; margin: 0; padding: 0; font-weight: 400; line-height:20px;  color:rgba(0,0,0,0.54);}
.answerRequestAction {border-top:1px solid rgba(0,0,0,0.12); padding-top: 8px;}
/* CSS for Common INNER STREATCH Layouts */
.wrapperLayout {display: block; position: relative; height: 100%; overflow-y: auto; width: calc(100% - 320px); overflow-x: hidden; overflow-y: auto;}
.fullStretchWrapper {display: flex; padding: 0; position: relative; margin: 0px; min-height: 100%; flex-direction: column;}
.containerBlock {display: flex; flex: 1 1 auto; min-height: 100%; position: relative; z-index: 1;}
.leftBlock {display: flex; flex: 0 0 auto; flex-direction: column; padding: 24px; border-right: 1px solid rgba(0,0,0,0.12); align-items:center; box-sizing: border-box; }
.RightBlock {display: flex; flex: 1 1 auto; flex-direction: column;  box-sizing: border-box; position: relative;}
.tabsHeadBlock {display: flex; flex: 0 0 auto; border-bottom: 1px solid rgba(0,0,0,0.12);}
.tabsContentBlock {display: flex; flex: 1 1 auto; padding: 24px; flex-direction: column;}
/* .contentViewBlock {flex: 1 1 auto; display:flex;} */
.contentViewBlock {flex: 1 1 auto; display:flex; justify-content: center;}
.mrgnLeft320 { margin: 0 0 0 320px;}
.margnTp16 {margin-top: 16px !important;}

/* .btnBothBlock {display: flex; justify-content: flex-end; padding: 0;}
.btnLeftBlock {margin-right: 16px;display: flex;}
.btnRightBlock {display: flex;} */

/* .btnPrmOutln {border-radius: 99px !important; height: 32px; line-height: 1.2 !important;}
.btnPrmFilled {box-shadow: none !important; border-radius: 99px !important; height: 32px;}
.btnPrmFilled:hover {background: var(--primaryHover); } */


.backLink {text-decoration: none; color: var(--primaryColor); display: flex; align-items: center; padding: 8px 16px;}
.backLink:hover {background-color: rgba(89, 186, 239,0.08); border-radius: 99px; padding: 8px 16px;}

.sliderClass {padding: 22px 0;}
.sliderClass button {width: 20px; height: 20px;}


/********* Container for Dashboard Slide In & Pop Up in Conference *********/

.rightContainer { display: flex; flex: 1; flex-direction: column; height: 100%; width: 100%; }
.rightContainerHeader { display: flex; height: 48px; flex: 0 0 auto; box-sizing: border-box; border-bottom: var(--brdrDark); align-items: center; padding: 0;  width: 100%; }
.rightHeadingTxtBlock { display: flex; box-sizing: border-box; align-items: center; padding: 0 16px; width: calc(100% - 48px);}
.rightHeadingAction { display: flex; width: 40px; height: 40px; flex: 0 0 auto; align-items: center; justify-content: center; cursor: pointer; border-radius: 50%; color: var(--blackTxtPrimaryDisable);}
.rightHeadingAction:hover { background-color: rgba(89, 186, 239,0.08); transition: all 0.5s ease; color: var(--primaryColor);}
.rightHeadingAction img { width: 20px; height: 20px; opacity: 0.38; }
.rightHeadingAction:hover img { opacity: 1; }


.rightContainerFooter { display: flex; height: 48px; flex: 0 0 auto; box-sizing: border-box; border-top: var(--brdrDark);  align-items: center; padding: 0 16px; position: relative;  width: 100%; }


.rightContainerBody { display: flex; box-sizing: border-box; flex: 1 1 auto; flex-direction: column; position: relative; height: calc(100% - 56px);  width: 100%; }

.listIcon {margin-right:0px !important;}
.listIcon svg{color:var(--blackTxtPrimaryInactive);}
.listIconImg {width:24px; height:24px;}
.listIconImg img {width:100%; height: 100%;}
.gifIconCustom {width:48px; height:auto; display: flex; flex:0 0 auto; box-sizing: border-box;}
.gifIconCustom img {width: 100%; height: 100%;}
/****** Common Flex Row & Column *******/
.flexRowContainer {display: flex; align-items: center !important; flex-direction:row !important; width: 100%;}
.flexColContainer {display: flex; flex-direction: column;}
.flex1 {flex:1; display:flex;}
.listMtngInfo { display: flex; width: 100%; margin-top: 8px;}
.listMeetingStatus { display: flex; border-radius: 50px; background-color: #FAFAFA; padding: 4px 8px; align-items: center; }
.listMeetingStatus span { font-weight: 500; font-size: 10px; color: rgba(0,0,0,0.54);}
.listMeetingStatus span.currentMtng { color: var(--greenDBg); font-size: 500;}
.listMeetingStatus span.nextMtng { color: var(--primaryDark); font-size: 500;}

.flexDescBox {flex:1; margin: 24px 0 0 24px;}
.flexDescBox textarea {min-height:256px; max-height:256px;}
/************* Radio Group for Resolutions **********/
.stRadioGroupItem {margin: 0!important; border-bottom:1px solid rgba(0,0,0,0.12);
    padding: 3.5px 0; flex:1 1 auto;}
.stRadioGroupItem input {text-align: end;}
.stRadioGroupItem > span:nth-child(2) {display: flex; flex: 1 1 auto;}
.stSecText {font-size: 12px !important; color: rgba(0,0,0,0.54) !important;}
.stRadioGroupItem:last-child {border-bottom: none;}


/***********Comoon Tabs Css ********/
.showInviteDetails { display: none; position: absolute; z-index: 1; }
.spanBold:hover .showInviteDetails { left: 72px; max-width: 288px; top: 66px; display: flex; position: absolute; z-index: 1; min-height: 40px; border-radius: 4px; background-color: #fff; box-shadow: 0 0 24px rgba(0,0,0,0.24); width: auto; min-width: 140px; padding: 24px 16px; box-sizing: border-box;}
.spanBold:hover .showInviteDetails:after { position: absolute; z-index: -1; border-top:12px solid transparent; border-right: 12px solid transparent; border-bottom: 12px solid #fff; border-left: 12px solid transparent; width: 0; height: 0; top: -24px; left: 50%; transform: translateX(-50%); content: ""; }
.infoIcon:hover .showInviteDetails { left: -5px; max-width: 288px; top: 56px; display: flex; position: absolute; z-index: 2; min-height: 40px; border-radius: 4px; background-color: #fff; box-shadow: 0 0 24px rgba(0,0,0,0.24); width: auto; min-width: 140px; padding: 24px 16px; box-sizing: border-box;}
.infoIcon:hover .showInviteDetails:after { position: absolute; z-index: -1; border-top:12px solid transparent; border-right: 12px solid transparent; border-bottom: 12px solid #fff; border-left: 12px solid transparent; width: 0; height: 0; top: -24px; left: 50%; transform: translateX(-50%); content: ""; }
.infoIcon:hover .showInviteDetails svg {fill:#ffa000; font-size: 24px;}
.colProfileView { display: flex; position: relative; flex:1;}
.colProfileView > .inviteeAvatar { display: inline-flex;  }
.colProfileView > .inviteeDetails { display: inline-flex; flex-direction: column; box-sizing: border-box; padding: 0 16px;}
.colProfileView > .inviteeDetails > h5{ font-size: 16px; font-weight: 400; margin: 0; color: var(--blackTxtPrimary);}
.colProfileView > .inviteeDetails > small { font-size: 12px; color: var(--blackTxtPrimaryInactive); }

/********** Progress Loading CSS **********/
  @keyframes animate-stripes {
      0% {
          background-position: 0 0;
      }
  
      100% {
          background-position: 60px 0;
      }
  }
  
  @keyframes auto-progress {
    0% {
      width: 0%;
    }
  
    100% {
      width: 100%;
    }
  }
  
  .progress-bar {
      background-color: #b3dceb;
      height: 40px;
      width: 450px;
      margin: 16px auto;
      border-radius: 5px;
      box-shadow: 0 1px 5px #b3dceb inset, 0 1px 0 #fff;
  }
  
  .stripes {
      background-size: 30px 30px;
      background-image: linear-gradient(
          135deg,
          rgba(0, 138, 188,.15) 25%,
          transparent 25%,
          transparent 50%,
          rgba(0, 138, 188,.15) 50%,
          rgba(0, 138, 188,.15) 75%,
          transparent 75%,
          transparent
      );
  }
  
  .stripes.animated {
    animation: animate-stripes 0.6s linear infinite;
  }
  
  .stripes.animated.slower {
    animation-duration: 1.25s;
  }
  
  .stripes.reverse {
    animation-direction: reverse;
  }
  
  .progress-bar-inner {
    display: block;
    height: 45px;
    width: 0%;
    /* background-color: #34c2e3; */
    border-radius: 3px;
    box-shadow: 0 1px 0 rgba(179, 220, 235, .5) inset;
    position: relative;
    animation: auto-progress 10s infinite linear;
  }
  
/************ End of Progress Loading **********/  
/***** Linear Progress Animation ************/
  @-webkit-keyframes loader {
    0% {
      width: 0;
    }
    20% {
      width: 10%;
    }
    25% {
      width: 24%;
    }
    43% {
      width: 41%;
    }
    56% {
      width: 50%;
    }
    66% {
      width: 52%;
    }
    71% {
      width: 60%;
    }
    75% {
      width: 76%;
    }
    94% {
      width: 86%;
    }
    100% {
      width: 100%;
    }
  }
  
  @keyframes loader {
    0% {
      width: 0;
    }
    20% {
      width: 10%;
    }
    25% {
      width: 24%;
    }
    43% {
      width: 41%;
    }
    56% {
      width: 50%;
    }
    66% {
      width: 52%;
    }
    71% {
      width: 60%;
    }
    75% {
      width: 76%;
    }
    94% {
      width: 86%;
    }
    100% {
      width: 100%;
    }
  }
  .linearprogress-bar {
    border-radius: 60px;
    overflow: hidden;
    width: 100%;
  }
  .linearprogress-bar span {
    display: block;
  }
  
  .bar {
    background: rgba(0, 0, 0, 0.075);
  }
  
  .linearprogress {
    -webkit-animation: loader 8s ease infinite;
            animation: loader 8s ease infinite;
    background: #75b800;
    color: #fff;
    padding: 5px;
    width: 0;
  }
  
  .linearprogress-bar {
    left: 50%;
    max-width: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0);
  }

/**********End of Linear Progress ***********/
.loadingRangerBlock { display: flex; width: 100%; padding-bottom: 16px; }
.linearProgressRanger>div {height:6px; width:100%;}

/******* Animation Hour-Glass ********/
#wrap {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* margin: auto; */
  }



/* PAYMENT SUCCESS AND FAILURE */

.circle72 {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circle72>img{ width: 100%; height:auto;}
.successColor { background-color: #54a162; background-color: var(--successColor); }
.failureColor { background-color: #ec655f; background-color: var(--failureColor); }
.successIcon {fill:#54a162!important;}
.failureIcon {fill:#ec655f !important;}
.pendingIcon {fill:#ff9200 !important;}

/**** Host Dialog CSS ****/
.hostDataBlock {display: flex; flex-direction: column; padding: 0; margin-top: 16px;}
.hostInputBlock {display: flex; align-items:center; background-color: #f7f8fa;
    margin: 0;
    padding: 0 16px;}
.hostTitle {flex:1;}
.hostAction {flex: 1; display: flex; justify-content: flex-end;}
.hostUser {background: #efefef; display: flex; flex-direction: column; padding: 16px;}
.justifyCenter {display: flex; justify-content: center !important;}
.justifyEnd {display: flex; justify-content: flex-end;}
.hostPwd {padding:24px 0;}
/**********Host Badges and Labels *****/
.packageLabel {
    color: var(--whitePrimary);
    padding: 2px 4px;
    margin-left: 4px;
    border-radius: 4px;
    font-size: 8px;
    font-weight: 550;
    box-shadow: 0 1px 2px rgba(0,0,0,0.24);
    text-transform: uppercase;
}
.alignCenter {align-items: center;}
.txtCenter {text-align: center;}
.clearAllBtnHidden {font-size: 12px !important; line-height: 20px !important; text-transform: capitalize !important;}
.clearAllBtnHidden svg {font-size: 16px !important;}
.leftIcon {margin-right: 8px!important;}
.buyNowBtn {padding: 0px 16px !important; box-shadow: none !important;}
.buyNowBtn svg {font-size: 20px !important;}
.buyNowBtnResponsive {display: none !important;}
.hostInfo { position: absolute; z-index: 1; width: 16px; height:16px; padding: 2px; background-color: #fafafa; border-radius: 50%; top: -10px; right: -6px; cursor: pointer; display: flex; align-items: center; justify-content: center;}
.hostInfo svg {fill:#ffa000 !important; font-size: 12px !important;}
.userLimitCount {color:var(--blackTxtPrimary); margin-left: 4px; font-weight: 550; font-size: 14px;}

/********* SNACKBAR CSS ************/
.success { background-color:#43a047;}
.error {background-color:#d32f2f;}
.info {background-color:#0077ad;}
.warning {background-color:#ffa000;}
.icon {font-size: 20;}
.iconVariant {opacity: 0.9; margin-right: 8px;}
.message {display: flex; align-items: center;}

.mobLogo {display: none;}

.homeCol2responsive {display:none;}
.btnLink {background-color: var(--primaryColor); color: #fff; padding:8px 16px; font-size: 16px; font-weight: 500;}

/******** Troubleshoot CSS *****/
.pageTableHead {padding:16px 24px; flex: 0 0 auto; display: flex; align-items: center; width:880px;}
.tableHeading {flex:1 1 auto;  margin: 0px; }
.tableActionBlock {flex:0 0 auto; align-items: flex-end;  margin: 0px;}
.tableActionBlock button {font-size:12px!important;}
.tableActionBlock button svg {font-size: 20px !important;}
.pageTableHead h4{font-size:24px; font-weight:500;}
.pageCentreTable {display:flex; flex: 1 1 auto;}
.tableCard {margin-bottom: 16px; border-top-left-radius: 4px;
    border-top-right-radius: 4px;}
.tableTitle {font-size: 16px; font-weight: 500;padding-left: 24px; flex-direction: column; display:flex !important; text-align: initial;}
.tableTitle .subTitle {font-weight: 500; font-size:12px; color:rgba(0,0,0,0.54) !important;}
.tableCol2 { display: flex; width: 50%; box-sizing: border-box; flex: 1 1 auto; margin:0 24px;}
.expansionPanelBlock {padding:0 !important; min-height: 64px !important;}
.expansionPanelBlock > div:first-child {align-items: center;} */
/* .rightIconBox {background-color: #ececec; padding:20px; display:flex;} */
.expandBtnMrg{margin:2px 0 !important;}
.disabledPanel {background-color: #fff !important;}
.disabledPanel .expansionPanelBlock {opacity:1 !important;}
.disabledPanel .expansionPanelBlock > div:last-child  {color: rgba(0,0,0,0.0)!important;}
.greenColor {color:#54a162;}
.redColor {color:#ec655f;}
.contentContainer {padding: 0; margin: 0; display: flex; flex-direction: column; flex: 1; align-items: center; justify-content: center;}
.tableWrapperH { position: relative; padding: 0; margin: 0; width: 100%; height:inherit; }
.tableWrapperBody { display: flex; min-height: 100%; margin: 0 auto;
    flex: 1 1 auto;
    width: 928px;}
.centerAlign{ flex-direction: column; align-items: center; justify-content: center; }
.panelFlexCol {flex-direction:column; margin-left:48px; }
.panelFlexCol svg {fill:rgba(0,0,0,0.38)!important; margin-right:8px;}
.panelFlexCol p {display:flex !important; align-items: center; margin:0 0 8px; font-size: 0.875rem;}
.panelFlexCol .txtProp {color: rgba(0,0,0,0.54)!important; margin-right:8px;}
.topLogoBlock {flex: 1 1 auto; padding: 0; box-sizing: border-box; justify-content: center; align-items: center;  padding: 12px 24px; border-bottom: 1px solid rgba(0,0,0,0.12);}
.expandedPanel{padding: 0 24px 8px 24px !important; flex-direction: column; display: flex;}
.expandedInfo {display: flex; align-items: center;flex:1; width:100% !important; box-sizing:border-box;}
.expandedInfo svg {flex: 0 0 auto;}
.expandedInfo span {flex:1 1 auto;}
.expandedInfo button {flex:0 0 auto;}
.expandedInfoDetails {padding:8px 16px; border-bottom: 1px solid rgba(0,0,0,0.12); display: flex;
    align-items: center; margin: 0 -24px 0;}
.contentListItem {display:flex; flex-direction:column; flex:1 1 auto;}
.expandedRoomList {background-color:#fafafa; margin: 0 -24px; padding:0px; box-sizing: border-box;}
.expandedPaper {background-color:#f5f5f5 !important; box-shadow: none !important; border:0 !important;}
.unExpandedPaper {box-shadow: none !important; border:0 !important;}
/*****************/

/****** FAQ *****/
.faqHeadBlock {padding:56px 24px; flex: 0 0 auto; display: flex; align-items: center; justify-content: center;}
.faqHeading { margin: 0px;  text-align:center;  }
.faqHeading h4 {font-size:24px; font-weight:500; color:rgba(0,0,0,0.87);}
.faqHeading p {font-size:16px; font-weight:500; color:rgba(0,0,0,0.38);}
.faqWrapperBlock { display: flex; min-height: 100%; margin: 0 auto; flex: 1 1 auto;background-color: #f7f8fa; padding:24px 0;}

.react-draggable {
    position: fixed !important;
    z-index: 9999;
    bottom: 0;
    right: 0;
    cursor: move;
}
.floatingP2P video {
    /* display: none; */
    width: 400px;
    height: 225px;
}
.floatingP2P #self {
    background-color: #191919;
    width: 160px;
    height: 90px;
    bottom: 3px;
    right: 0;
    position: absolute;
}
.floatingP2P #remote {
    background-color: #272727;
    transform: scale(0, 0);
    transition: all 0.6s ease;
    transform-origin: right bottom;
}

.p2pEnd {
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateZ(50px);
    margin: 0;
    width: 40px;
    height: 40px;
}

.hideout {
    display: none;
}

.alignFlexStart {display: flex; align-items: flex-start !important;}
.txtValueBlock {display: flex; align-items: center; font-size: 14px;
    }
.txtValueBlock .txtProp{color: rgba(0,0,0,0.54); flex:0 0 auto;}
.txtValueBlock .txtValue {color: rgba(0,0,0,0.87); flex:0 0 auto; margin-left:8px; display: flex;
    align-items: center;}
.txtValueBlock .txtValue input{padding: 5px 8px;
    margin: 0;
    font-size: 16px;
    outline: none;
    border-radius: 3px;
    margin-right: 6px;
    border: 1px solid rgba(0,0,0,0.12);
    box-sizing: border-box;
    width: 70%;}
.customOutlinedInput {padding:16px 8px !important; }
.whiteOutlinedInput div {background-color:#fff !important; font-size:14px !important;}
.whiteOutlinedInput>div{padding:10px 14px;}
.headingListItem {background-color: #fafafa !important; }
.headingListItem span {font-weight: 550;
color: rgba(0,0,0,0.54);
font-size: 14px;}
/********* Slide Down Popuo ********/
.openFilter { position: absolute; z-index: 1; min-width: 288px; min-height: auto; border-left: var(--brdrDark); top: 112px; right:16px; 
  background-color: var(--whitePrimary); 
  -ms-transition: all 0.5s ease; -moz-transition: all 0.5s ease; -webkit-transition: all 0.5s ease; transition: all 0.5s ease; 
  -ms-box-shadow: -1px 1px 16px -2px rgba(0,138,188,0.24); -moz-box-shadow: -1px 1px 16px -2px rgba(0,138,188,0.24); -webkit-box-shadow: -1px 1px 16px -2px rgba(0,138,188,0.24); box-shadow: -1px 1px 16px -2px rgba(0,138,188,0.24);
  -ms-transform: translateY(-18px);-moz-transform: translateY(-18px); -webkit-transform: translateY(-18px); transform: translateY(-18px);
  height: 0; opacity: 0; visibility: hidden;
}
.openFilter.slideDownFilter {-ms-transform: translateY(-18px);
  -ms-transform: translateY(0px); -moz-transform: translateY(0px); -webkit-transform: translateY(0px); transform: translateY(0px);
  
  visibility: visible; opacity: 1; height:auto; }
.openFilter:after { content:""; position: absolute; z-index: 0; width: 0; height: 0; border-top: 16px solid transparent; border-right: 16px solid transparent; border-bottom: 16px solid #fff; border-left: 16px solid transparent; top: -24px; right: 24px; }

.openVideoFilter { position: absolute; z-index: 1000; min-width: 288px; min-height: auto; border-left: var(--brdrDark); top: 64px; right:0px; 
  background-color: var(--whitePrimary); 
  -ms-transition: all 0.5s ease; -moz-transition: all 0.5s ease; -webkit-transition: all 0.5s ease; transition: all 0.5s ease; 
  -ms-box-shadow: -1px 1px 16px -2px rgba(0,138,188,0.24); -moz-box-shadow: -1px 1px 16px -2px rgba(0,138,188,0.24); -webkit-box-shadow: -1px 1px 16px -2px rgba(0,138,188,0.24); box-shadow: -1px 1px 16px -2px rgba(0,138,188,0.24);
  -ms-transform: translateY(-18px);-moz-transform: translateY(-18px); -webkit-transform: translateY(-18px); transform: translateY(-18px);
  height: 0; opacity: 0; visibility: hidden;
}
.openVideoFilter.slideVideoFilter {-ms-transform: translateY(-18px);
  -ms-transform: translateY(0px); -moz-transform: translateY(0px); -webkit-transform: translateY(0px); transform: translateY(0px);
  
  visibility: visible; opacity: 1; height:auto; }
.openVideoFilter:after { content:""; position: absolute; z-index: 0; width: 0; height: 0; border-top: 16px solid transparent; border-right: 16px solid transparent; border-bottom: 16px solid #fff; border-left: 16px solid transparent; top: -24px; right: 24px; }


.arrowIcon {content:'\&#x2304;'; transform: rotate(0deg); transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;}
.arrowIcon:after {transform: rotate(180deg);}


/*******Slide Up Popup*********/
.dropUpBox { 
    /* display: none; position: absolute; min-width: 168px; max-width: 240px; min-height: 80px; background-color: var(--whiteBg);bottom: 64px; right: -28px; margin-bottom: 24px; box-shadow: 2px 2px 38px rgba(0,0,0,0.38); z-index: 20;  */
    position: absolute;
    min-width: 240px;
    max-width: 240px;
    min-height: 80px;
    background-color: var(--whiteBg);
    bottom: 64px;
    left: 56%;
    margin-bottom: 24px;
    box-shadow: 2px 2px 38px rgba(0,0,0,0.38);
    z-index: 20;
    border-radius: 3px;
    display: none;
}
/* .dropUpBox:after { content:""; position: absolute; z-index: 0; width: 0; height: 0; border-top: 12px solid #fff; border-right: 12px solid #fff; border-bottom: 12px solid transparent; border-left: 12px solid transparent; bottom: -24px; right: 0; } */
.dropUpBox.slideUp { display: flex; }

.openDPOptions { 
    /* display: none; position: absolute; min-width: 168px; max-width: 240px; min-height: 80px; background-color: var(--whiteBg);bottom: 64px; right: -28px; margin-bottom: 24px; box-shadow: 2px 2px 38px rgba(0,0,0,0.38); z-index: 20;  */
    position: absolute;
    min-width: 240px;
    max-width: 240px;
    min-height: 80px;
    background-color: var(--whiteBg);
    bottom: 64px;
    right: 32px;
    margin-bottom: 24px;
    box-shadow: 2px 2px 38px rgba(0,0,0,0.38);
    z-index: 20;
    border-radius: 3px;
    display: none;
}
.openDPOptions:after { content:""; position: absolute; z-index: 0; width: 0; height: 0; border-top: 12px solid #fff; border-right: 12px solid #fff; border-bottom: 12px solid transparent; border-left: 12px solid transparent; bottom: -22px; right: 0; }
.openDPOptions.slideUp { display: flex; }

.slideupMenu { 
  /* display: none; position: absolute; min-width: 168px; max-width: 240px; min-height: 80px; background-color: var(--whiteBg);bottom: 64px; right: -28px; margin-bottom: 24px; box-shadow: 2px 2px 38px rgba(0,0,0,0.38); z-index: 20;  */
  position: absolute;
  min-width: 224px;
  max-width: 240px;
  min-height: 80px;
  background-color: var(--whiteBg);
  bottom: 64px;
  left:70%;
  margin-bottom: 24px;
  box-shadow: 2px 2px 38px rgba(0,0,0,0.38);
  z-index: 20;
  border-radius: 3px;
  display: none;
}
/* .openShareTools:after { content:""; position: absolute; z-index: 0; width: 0; height: 0; border-top: 12px solid #fff; border-right: 12px solid #fff; border-bottom: 12px solid transparent; border-left: 12px solid transparent; bottom: -22px; right: 0; } */
.slideupMenu.slideUp { display: flex; }

.openShareMenu {left:70%;}
.openRecTools {left:45%;}
.openBroadcastTools {left:45%;}

.activeTxtList span{ color:var(--primaryColor);}
.activeTxtList svg{ fill:var(--primaryColor);}

.addMoreBtn {background-color: #b3dceb!important;}
/********* Table Format *********/
.customTableHeading {background-color: #f4f8fb !important;}
.customTableHeading tr th {color: rgba(0,0,0,0.54) !important; font-size:0.85rem !important; font-weight:500 !important;}
.customTableCell td {padding:8px 24px !important;}
.customTableCell td:first-child {color:#336ebf;}
.customTableCell td:last-child {width:72px; padding:0 8px;}
.customTableCell:hover>.clickableRowItem{text-decoration: underline !important; cursor: pointer;} 
.paginationIcons{display: flex;}

/******** Table Slider Right ********/
.infoDrawerRight { position: fixed; z-index: 1000; width: 424px; height: calc(100vh - 64px); right: 0; border-left: var(--brdrDark); top: 64px; background-color: var(--whitePrimary); -ms-transition: all 0.3s ease; -moz-transition: all 0.3s ease; -webkit-transition: all 0.3s ease; transition: all 0.3s ease; -ms-box-shadow: -1px 1px 8px -2px rgba(0,0,0,0.24);; -moz-box-shadow: -1px 1px 8px -2px rgba(0,0,0,0.24);; -webkit-box-shadow: -1px 1px 8px -2px rgba(0,0,0,0.24);; box-shadow: -1px 1px 8px -2px rgba(0,0,0,0.24); -ms-transform: translateX(424px); -moz-transform: translateX(424px); -webkit-transform: translateX(424px); transform: translateX(424px); }
.slideInDrawer { -ms-transform: translateX(0px); -moz-transform: translateX(0px); -webkit-transform: translateX(0px); transform: translateX(0px); }

.listHeading {font-size: 16px; font-weight:400; color:rgba(0,0,0,0.54); border-bottom: 1px solid rgba(0,0,0,0.12);}


/********* RESPONSIVE CSS ************/
@media (min-width: 1025px ) and (max-width: 1366px) and ( orientation: landscape) {
  .bdRatioBlock { width: 100%; }
}

@media (min-width: 1025px ) and (max-width: 1366px) and ( orientation: portrait) {
  .bdRatioBlock { width: 100%; }
  .container1140 {width:100% !important;}
}





/********** TABLET RESPONSIVE **************/
@media (min-width: 768px) and (max-width: 1024px) {
  .mainContainer {top:56px !important; height: calc(100% - 56px) !important;}
  .removeTop {top:0 !important; height: 100vh !important;}
  .floatingJoinBtn{display: none !important;}
  .buyNowBtn {display:flex !important;}
  .container1140 {width:100% !important;}
  .rowBlock {flex-direction: row;}
  .rightAction {padding:0 8px;}
  .rightAction .spanClass {display: none;}
  .containerBlock {flex-direction: row;}
  .tabsHeadBlock {width:100% !important;}
  .leftBlock {border:1px solid rgba(0,0,0,0.12) !important;}
  .editFormInput {flex-direction: row;}
  .editFormInput>.inputFieldBlock {margin-right:0 !important;}
  .infoContent {flex-direction: row}
  .infoAction {justify-content: unset; width: unset;}
  .tabNavResponsive{display:none !important;}
  .tabNav {display:flex !important;}

  .rightHeaderBlock {padding:0 16px;}
  .rightHeaderBlock h2 {font-size: 0.875rem;}
  .dialogContent960 {min-width: initial !important;}
  .hwLogo{height: 32px !important;}

  .infoDialog {max-width: 536px !important;}
  .hostDetailsBlock {padding: 0 !important;}
  .block24 {padding: 24px 24px !important;}
  /******* Meetings Page *******/
  .rowBlock {flex-direction: column !important;}
  .innerFixedBlock {position: relative !important; top:0 !important; width:100% !important;}
  .innerRightBlock {margin: 0 !important; min-height:100%;}

  /***** MY Profile ******/
  .sideNav {display: none !important;}
  .mrgnLeft320 {margin:0; width: 100%;}
  .containerBlock {flex-direction: column;}
  .leftBlock {border:none!important;}
  .tabsHeadBlock {width:100% !important;}
  .block320 {width:100%;}
  .tableWrapperBody {width:100%;}
  .pageTableHead {width:100%;}

  .mobLogo {display: flex; margin-bottom: 24px; align-items: center; justify-content: center;}
  .mobLogo >img {height: 24px;}
  .mobLogo>h1 {display: none;}

  .bdRatioBlock {  background-color: transparent; width: 100%; }
  .browsingBlock {margin:16px 24px;}
  .dropUpBox {right:8%; left:unset;}
}



  @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      .responsiveDialog>div:last-child>div {margin:16px !important; min-width: calc(100% - 32px);}
      .bdRatioBlock { height: calc(100% - 80px); background-color: transparent; width: calc(calc(100vh - 192px) * 1.77777778); }
    
    }

  /* @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .resFlexCol {display: flex; flex-direction: column !important;}
    .resFlexCol .flexDescBox {margin:0;}
  } */
 
/************** MOBILE RESPONSIVE *************/

@media (min-width: 320px) and (max-width: 767px) {
    .mainContainer {top:56px !important; height: calc(100% - 56px) !important;}
    .removeTop {top:0 !important; height: 100vh !important;}
    .displayOrNot {display: flex !important;}
    .responsiveCtrl {display: flex !important; flex-direction:column;}
    .formContainer {padding: 0 !important;}
    .wrapperBody {flex-direction: column;}
    .col2 {width:100% !important;}
    .block24 {padding: 24px 16px !important;}
    .contentContainer {overflow-x: hidden; overflow-y: auto; justify-content: unset!important;}
    .block320 {width: 100%;}
    .openVideoFilter {top:88px;}
    .rightAction {padding:0 8px;}
    .rightAction .spanClass {display: none;}
    /* start new css for tabnav responsive in dashboard - manish */
    .tabNav {padding:0 !important;}
    .tabNav ul li a { font-size:12px !important;} 
    .tabNav ul li a.activeTabNav  {font-size:12px !important;}
    .btnRightBlock button{font-size: 12px !important;}
    .homeCol2responsive {padding:0 !important;}
    .dialogCustomContent {min-width: 176px !important;}
    .infoDialog {max-width: 280px !important;}
    .hostDetailsBlock {padding: 0 !important;}
    .btnBlockHeading h3{font-size: 1.2rem !important;}
    .bdRatioBlock {width:100%; background-color: transparent;}
    .column50 {width: calc(100% - 8px); display: block;
      margin-bottom: 16px;}

    .rightHeaderBlock {padding:0 16px;}
    .rightHeaderBlock h2 {font-size: 0.875rem;}
    .dialogContent960 {min-width: initial !important;}
    .resFlexCol {display: flex; flex-direction: column !important;}
    .resFlexCol .flexDescBox {margin:0;}
    .hwLogo{height: 32px !important;}
    .customFormGroup {flex-wrap: wrap;}
    .customOutlinedFormControl {min-width: 128px;}
    /**** Invite Page *****/
    .responsiveDialog>div:last-child>div {margin:16px !important; min-width: 264px;}
    .pageCenterText h3 {font-size:24px!important;}
    .tabNavResponsive { display: flex !important; flex: 0 0 auto; background-color: var(--lightSecondaryGray); position: relative; z-index: 1; height: 56px; top: 0; left: 0; right: 0; align-items: center; padding: 0; box-sizing: border-box;}
    .tabNavResponsive ul { display: flex; margin: 0; padding: 0; list-style-type: none; }
    .tabNavResponsive ul li { display: inline-flex; position: relative; }
    .tabNavResponsive ul li a {text-align:center; display: flex; font-size:12px; padding: 9px 16px; margin: 0; text-transform: uppercase; text-decoration: none; border-top: 3px solid transparent; border-bottom: 3px solid transparent; color: var(--blackTxtPrimaryInactive); font-weight: 450;}
    .tabNavResponsive ul li a:hover { border-bottom: 3px solid var(--primaryLight); color: var(--primaryColor); }
    .tabNavResponsive ul li a.activeTabNav { border-bottom: 3px solid var(--primaryColor); color: var(--primaryColor); }
    .buyNowBtn {display: none !important;}
    
    /* end new css for tabnav responsive in dashboard - manish */
    
    .container1024 {width:95% !important;}
    .contentTerms {margin:0 16px;box-sizing: border-box;}
    .app {overflow: hidden;}
    .header {padding: 0 16px !important;}
    .logoBlock {width:96px !important;}
    .logoBlock img {width: 100%; height: 100%;}
    .userName {padding: 0 8px !important;}
    .userAvatar {width: 32px !important; height: 32px !important;}

    .rowBlock {flex-direction: column !important;}
    .innerFixedBlock {position: relative !important; top:0 !important; width:100% !important;}
    .innerRightBlock {margin: 0 !important; min-height: 100%;}

    .listBody {padding: 0 16px !important;}
    .listTitle {width: 152px !important;}
    .listTitle small span, .spanBold {text-overflow: ellipsis; overflow: hidden;}
    .listHeadingLeft {padding:0 8px 0 16px !important;}
    .listHeadingLink {padding:0 16px 0 8px !important;}
  
    .rightContainer{height: 100%; width: 100%;}
    /* .userListGroupContainer {min-height: 100%;} */
    .container1140 {width: 100% !important;
        margin: 0 !important;}
    .buyNowContainer {width:unset !important; padding: 16px !important;}
    .cornerIcon {position: unset !important;}
   
    .roomBlock {margin: 0; margin-bottom: 0 !important;}
    .roomBlock2, .stRadioBlock {width: 100% !important;}

    .confResoBlock>div {flex-direction: column !important;}
    .roomContenTxt {flex-direction: column; text-align:center; margin-top: 8px;}
    .roomContenTxt p {padding-right:0!important;}
    .roomContenTxt a {font-size:0.75rem !important;}
    .roomInnerContent {padding: 0!important;}
    .roomInnerContent label {margin-right:0!important;}
    
  
    .floatingJoinBtn { z-index: 3; position: fixed; top: 64px; align-items: center; justify-content: center; display: flex; font-size: 32px; color: #fff; box-sizing: border-box; cursor: pointer; right: 16px; left: 16px; justify-content: center; align-items: center; }
    .floatingJoinBtn button {height:48px !important; width:100% !important; border-radius:0 !important; margin:4px 0;}
    .floatingBtnBg {background-color: var(--secondaryBg) !important;}
    .floatingBtnBg:hover {background-color: var(--secondaryDarkBg) !important;}
    .buyNowBtnResponsive {margin: 16px 0 !important; display: flex !important; border-radius: 50px !important;}
    
    /* .floatingJoinBtn:after {
    content: "";
    position: absolute;
    z-index: -1;
    width: 0;
    height: 0;
    top: 0px;
    right: 130px;
    background-color: transparent;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    border-top: 24px solid transparent;
    border-right: 24px solid var(--secondaryBg);
    border-bottom: 24px solid transparent;
    border-left: 24px solid transparent;
    box-shadow: -1px 8px 22px rgba(0,0,0,0);
} */
    .dialogContent508 {min-width: unset !important; padding:16px !important;}
    .certificatePopup {min-width: unset; padding: 0 !important;}
    .statsInfoDetails {flex-direction:column; overflow-x:hidden; overflow-y:auto;}
    .dialogInfoProperties {padding:4px 8px;}
    .statsDividedContent {margin:0;}
    .dividedInfo>ul>li .txtProp {min-width:86px;}
    .dividedContent {min-width:unset; flex-direction: column;
        box-sizing: border-box;
        align-items: center;}
    .dividedHeader {padding-top:16px;}

    /***** MY Profile ******/
    .sideNav {display: none !important;}
    .mrgnLeft320 {margin:0; width: 100%;}
    .containerBlock {flex-direction: column;}
    .leftBlock {border:none!important;}
    .tabsHeadBlock {width:100% !important;}
    
    .infoContent {flex-direction: column}
    .infoAction {justify-content: flex-end;
        display: flex;
        width: 100%;
      flex:unset !important;}
    .editFormInput {flex-direction: column;}
    .editFormInput>.inputFieldBlock {margin-right:0 !important;}
    .width50 {width:100% !important;}
 
    .mobLogo {display: flex; margin-bottom: 24px; align-items: center; justify-content: center;}
    .mobLogo >img {height: 24px;}
    .hideInMobile {display: none;}

    .responsiveSearch {padding:0 !important;}
    .responsiveSearch>.searchIcon {left: 0!important;}
    .responsiveSearch input {padding-left:32px !important;}
    
    .bmContainer {flex-direction: column; margin-top: 24px;}
    .promoBlock {position: relative !important;}
    .promoBlock input {min-width:188px !important;}
    .bmBlock3 {width:100% !important;}

    /******* CALLING SCREEN *******/
    .dialogPaper div>div {max-height:100% !important; margin:0 !important;}
    
    .callEndAction {padding: 0 !important;}
    .keepCntr {position: absolute !important; top:-64px !important;}
    .nameBox {top:4%; bottom:unset;}
    
    /********* Host PopUp ***********/
    .selectUserListItem>.selectUserAvatar,
    .searchListItem>.searchUserAvatar{width:24px; height: 24px;}
    .selectUserListItem>.selectUserInfoDetails,
    .searchListItem>.searchUserDetails { width:calc(100% - 56px);}
    .selectUserTitleBlock>.selectUserTitle h3,
    .searchUserTitleBlock > .searchUserTitle h3 {font-size:14px;}
    .selectUserTitleBlock>.selectUserTitle small,
    .searchUserTitleBlock>.searchUserTitle small {display: none;}
    .selectUserTitleBlock>.selectUserTitle,
    .searchUserTitleBlock > .searchUserTitle  {width: unset!important;}
    /* .searchBlock {padding:0;} */
    .floatingSearchList {width:100%;}

    /******** Troubleshoot ********/
    .tableWrapperBody {width:100%;}
    .pageTableHead{width:100%; flex-direction: column;}
    .pageCentreTable {flex-direction: column;}
    .tableCol2 {width:unset; margin:0 16px;}
    .tableActionBlock {display:flex; flex-wrap:wrap; align-items: center; justify-content: center; padding:8px 16px;}
    .tableActionBlock button {margin-bottom:8px; margin-right:0 !important; flex:1 1 auto;}
    .tableActionBlock button:first-child {margin-right:8px !important;}
    /***********************/
    /****** Slidein Responsive ******/
    /* .infoDrawerRight {width:100%; transform: translateX(105%);} */

    .tabsContentBlock {padding: 16px; }
    
    .infoDrawerRight {width:100%; transform: translateX(105%); -webkit-transform: translateX(105%);}
    .slideInDrawer { -ms-transform: translateX(0px); -moz-transform: translateX(0px); -webkit-transform: translateX(0px); transform: translateX(0px); }
    .responsiveDialog>div:last-child>div {margin:16px !important; min-width: calc(100% - 32px);}
    .browsingBlock {margin:16px 24px;}
    .dialogVideo div>div {width:100%; max-height: inherit;}
    /* .</a>Body {
      height: calc(100vh - 104px);
      width: 100%;
      flex: initial; position: relative;
    } */
    .dropUpBox {right:8%; left:unset;}
  
  }

    @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
      /* .bdRatioBlock {    background-color: transparent;
        height: calc(100vh - 64px);
        width: calc(calc(100vh - 64px) * 1.77777778);} */
        .responsiveDialog>div:last-child>div {margin:16px !important; min-width: calc(100% - 32px);}
        .bdRatioBlock { height: 100%;
          background-color: #000;
          /*width: calc(calc(100vh - 136px) * 1.77777778);*/
          width: calc(calc(100vh - 168px) * calc(16/9));
        }
      }

    @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
      .answerRequestDialog {flex-direction: column;}
      .answerRequestIcon {width:96px; margin-bottom: 16px;}
      .answerRequestTxt {margin-left:0;}
      .responsiveDialog .MuiDialog-paper{margin:16px; min-width: calc(100% - 32px);}
    }

    
    