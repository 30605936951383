:root{
	--loginBg: linear-gradient(90deg, #2F4872, #478FBB, #2F4872 );
    --whiteBgInavtive: rgba(255,255,255,0.7);
    --whiteBg: rgba(255,255,255,1);
}
.whiteTx { color: var(--whiteBg);}

.homeWrapper {display: block; height: 100%; overflow-x: hidden; overflow-y: auto; width: 100%;}
.homeContainer { display: flex; flex: 1; position: relative; background: #ffffff; min-height: 100%;}
.homeCol2 { display: flex; position: relative; flex: 1; box-sizing: border-box; flex-direction: column; padding: 16px}
.homeColG { display: flex; position: relative; flex: 1; box-sizing: border-box; flex-direction: column; padding: 0px}
.bannerPort { display: none; }
.hwLogo { height: 40px; width: auto; }
.hwHeader { height: 56px; }

/* .bannerPort { align-items: center; justify-content: center; display: flex; flex: 1; background-image: url("/images/banners/instavc-b2.jpg"); background-repeat: no-repeat; background-size: contain; position: relative;} */
.brdrWLeft { border-left: 1px solid rgba(255,255,255,0.12); }
.absFade { position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: 0; background-color: rgba(0,0,0,0.5);}
.brandingBlock { width: 160px; display: flex; align-items: center; justify-content: center; z-index: 1; }
.brandingBlock > img { width: 100%; height: auto; }
.headingLine { font-size: 2.1rem; font-weight: normal;margin-bottom: 40px;}
.txtCenter {text-align: center !important;}
.puhchLine { margin: 0; font-size: 1.5rem; color: rgba(255,255,255,1); line-height: 2rem; }
.inlineBlock { display: flex; position: relative; padding: 16px 0;}

.inlineBlock > span { margin-right: 8px; color: #fff; font-size: 14px; text-transform: uppercase; }
.copyRtTxt { color: rgba(255,255,255,0.5); margin: 0; font-size: 0.9rem; }
.box475 { width: 475px; display: flex; align-items: flex-start;}
.tcAnchor { text-decoration: underline; color: #ffffff; margin: 0; display: flex;margin-left: 8px;}
.tcRtTxt {color: rgba(255,255,255,0.5); margin: 0; font-size: 0.9rem; display: flex; }

.homeResBranding { display: flex; flex: none; width: 100%; align-items: center; justify-content: center; margin-bottom: 24px; }
.homeResBranding img { height: 64px; }
.homeResLTxt { display: none; } 
.notInMob { display: none;}


.captchaBox { display: flex; flex: none; width: 100%; }
.captchaBox>div,.captchaBox>div>div,
.captchaBox>div>div>div { display: flex; flex:none; width: 100% !important;}
.captchaBox #rc-anchor-normal, .captchaBox #rc-anchor-normal
.captchaBox #rc-anchor-normal iframe { width: 100% !important; }

.respdngHSM { padding: 0 0.5rem }


/********** TABLET RESPONSIVE **************/
/* @media (min-width: 768px) and (max-width: 1024px) {
    .bannerPort {display: none !important;}
    .brdrWLeft {border-left: unset !important;}
    .homeCol2 {padding:24px;}
} */

/************** MOBILE RESPONSIVE *************/
/* @media (min-width: 320px) and (max-width: 767px) {
.bannerPort {display: none !important;}
.brdrWLeft {border-left: unset !important;}
.homeContainer {flex-direction: column;}
.box475 {width: 288px !important; text-align: center; margin-top: 16px;}
.inlineBlock>span {font-size: 12px !important;}
.homeCol2 {padding:24px;}
} */

@media (min-width: 1113px) {
    .notInMob { display: flex; }
    .respdngHSM { padding: 0 0.75rem }
    .hwHeader { height: 64px; }
    .bannerPort { align-items: center; justify-content: center; display: flex; flex: 1; background-image: url("/images/banners/instavc-b2.jpg"); background-repeat: no-repeat; background-size: contain; position: relative;}
    .homeCol2 { padding: 40px; }
    .homeResBranding { display: none; }
    .homeResBranding .bradingLogo { width: 104px; height: auto; }
    .homeResLTxt { display: block; font-size: 2.1rem; font-weight: normal; margin-bottom: 40px;} 
}