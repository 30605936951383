.hmBlockCntnr { display: block; height: 100%; width: 100%; overflow-x: hidden; overflow-y: auto; }
.hmFlexCntnt { display: flex; flex: 1 1; position: relative; min-height: 100%; background-color: var(--whiteBg); }
.hmResHalfCol { display: flex; flex: 0 0 100%; flex-direction: column; }
.hmResHalfCol.resHide { display: none; }
.hmFtr { height: 56px; align-items: center; justify-content: center; padding: 0 16px; position: relative; z-index: 1; bottom: 0;background-color: var(--whiteBg); border-top: 1px solid var(--brdrColor); }
.copyRightTxt { font-size: 0.75rem; line-height: 1; color: var(--blackTxtPrimaryInactive); text-align: center; }
.formCol { display: flex; flex: none; flex-direction: column; height: calc(100vh - 160px); overflow-x: hidden; overflow-y: auto; scroll-behavior: smooth; }
.langRow { height: 56px; justify-content: flex-end; }
.hmBrandingBox { display: inline-flex; min-width: 40px; height: 48px; position: relative; left: 118px; }
.hmBrandingBox img { height: inherit; width: auto; }

.homeFormCntnr{display: flex;}
.handleResBranding { align-items: center; justify-content: center; height: 56px; margin: 40px 0 64px; }
.handleResBranding img { height: 48px; width: auto; }


.formResWidth { width: calc(100% - 24px); margin: 0 auto; }

.pstnRelative { position: relative; }
.topAbslutDropdown { display: inline-flex; min-width: 40px; position: absolute; flex: none; right: 36px; top: -16px; }

.hmBtnBox { margin-top: 24px;}
.hmBtnPrimary { background-color: var(--primaryColor) !important; color: var(--whiteBg) !important; }
.hmBtnPrimary:hover { background-color: var(--primaryDark) !important; }
.hmBtnPrimary:disabled {background-color: var(--primarySuperLight) !important; }

.hmBannerFxdBlock { display: none; }
.hmBannerBlock { display: block; width: 100%; height: auto; }

.hmErroBlock { background-color: var(--errorLight); align-items: center; justify-content: center; padding: 13px 16px; border-radius: 4px; margin-bottom: 12px; }

.btmOptnCol { margin-top: 40px; align-items: center; }

.rowMid { justify-content: center; }

.hmCaptchaBox { display: flex; flex: none; width: 100%; margin-top: 12px;}

.otpGroup { display: flex; flex: none; width: 100%; flex-wrap: wrap; }
.optInputItem { display: inline-flex; flex: 1; margin: 0 2px; width: calc(17% - 6px); text-align: center; height: 40px; border-radius: 4px; border: 1px solid transparent; background-color: var(--primarySuperLight); font-size: 1.25rem; color: var(--primaryColor); font-weight: 600; }
.optInputItem:focus { outline: 2px solid var(--primaryColor); }
.optInputItem:autofill { background-color: var(--primaryColor); color: var(--whiteBg);}
.optSendAgain { padding: 12px 0 24px; align-items: center; justify-content: space-between; border-bottom: 1px solid var(--brdrColor); }


/* TYPOGRAPHY */
.hmTitleTxt { display: block; font-size: 2.0rem; margin-bottom: 40px; font-weight: 500; word-break: break-word; }
.hmSHTxt { font-size: 1.125rem; font-weight: 400; word-break: break-word; margin-bottom: 4px; color: var(--blackTxtPrimaryInactive);}
.hmSMTxt { font-size: 0.75rem; font-weight: 400; word-break: break-word; color: var(--blackTxtPrimaryInactive);}
.ftnAnchorTxt { display: inline-flex; color: var(--primaryColor); font-size: 0.75rem; font-weight: 500; text-decoration: underline; padding: 0 0 0 8px; margin-top: 4px; }
.hmBtnAnchor { font-size: 1.0rem; font-weight: 500; color: var(--primaryColor); text-decoration: none; margin: 0; display: inline-flex; padding-left: 8px; }
.hmBtnAnchor:hover { color: var(--primaryDark); text-decoration: underline; }
.errorTxt { font-size: 0.875rem; font-weight: 400; color: var(--dangerBg); word-break: break-word; line-height: 1.24;}
.errorTxt.lgTxt { font-size: 1.0rem; font-weight: 500; }
.hmTitleTxt.hideInMob { display: none;}
.hmMsgTxt { font-size: 0.875rem; line-height: 1.4; margin-bottom: 12px; }

/* RES PADDINGS */
.pdngVRes { padding: 40px 0 56px; }
.pdngHRes { padding: 0 16px; }
.pdngResMd { padding: 24px; }

/* MARGINS */
.autoMrgn { margin: auto; }
.mrgnBtmSM { margin-bottom: 12px;}

@media (min-width: 768px) and (max-width: 1112px) {
    .formResWidth { width: calc(100% - 180px); margin: 0 auto; }
    .topAbslutDropdown { top: 0; right: 106px;}
    .ftnAnchorTxt { margin-top: 0; padding-left: 8px; }
}



@media (min-width: 1113px) {
    .hmResHalfCol.resHide { display: flex; }
    .hmResHalfCol { flex: 0 0 50%; }
    .hmFtr { height: 64px; }
    .pdngVRes { padding: 40px 0 64px; }
    .pdngHRes { padding: 0 24px; }
    .ftnAnchorTxt { margin-top: 0; padding-left: 8px; }
    .pdngResMd { padding: 24px; }
    .handleResBranding {display: none; }
    .formResWidth { width: calc(100% - 190px) !important; margin: 0 auto; }
    .topAbslutDropdown { top: 0; right: 118px;}
    /* .hmBannerFxdBlock { display: flex; flex-direction: column; position: fixed; top: 50%; width: auto; -moz-transform: translateY(-50%); -webkit-transform: translateY(-50%); transform: translateY(-50%); left: 118px; } */
    .hmBannerFxdBlock { display: flex; flex-direction: column; position: relative; top: 0; width: 84%; }
    .hmBannerBlock img { height: 480px; width: auto;}
    .hmTitleTxt.hideInMob { display: block; } 
}